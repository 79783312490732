.curtin-uni-go-greater{
	img.foot-image.sux {
		bottom: -150px !important;  
	}
	.full-bleed-one {
		margin-left:14%;
	}
	.full-bleed-two {
		margin-right:14%;
	}
	.full-bleed-three {
		margin-top:0 !important;
		margin-bottom:50px;
	}
	.copy-two .component-case-copy-wrapper {
		margin-top:0;
	}
	img.arrow-right {
	    position: absolute;
	    right: -150px;
	    z-index: -1;
	    top: 400px;
	    width: 400px;
	    @media(max-width:600px){
	    	opacity:.4;
	    }
	}
	.component-case-images-gifs-wrapper {
		margin-top:150px !important;
		margin-bottom:50px;
		padding-bottom:50px;
		img {
			width:300px;
			margin:0 15px;
			&:nth-child(1){
				position:relative;
				top:-50px;
				@media(max-width:950px){
					top:0;
				}

			}
			&:nth-child(2){
				top:50px;
				@media(max-width:950px){
					top:0;
				}
			}
		}
	}
	.component-header-inner-case {
		.sub-wrap {
			text-align:center;

		}
		.foot-image {
			bottom:-250px;
		}
	}
	.stat-wrap{
		 background-repeat:no-repeat;
		 background-size:contain !important;
	}
	.footer-images {
		.stat-wrap {
			background-size:cover !important;
		}
	}
}