@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';


.Ceri-wrapper{

  .component-header-inner-case .parallax-one{
    margin-top: -80px;
    .header-image{
    }
    .hover-back{
      position: absolute;
      top: -12px;
      left: 0;
      width: 200px !important;
      z-index: -1;
    }
    .hover-front{
      position: absolute;
      bottom: -12px;
      right: 17%;
      width: 200px !important;
      z-index: -1;
    }

    @media (max-width:$large_tablet){
      margin: 20px 0 80px;
      .hover-front{
        right: 0;
      }
    }
    @media (max-width:$mobile){
      padding: 0;
      .hover-back{
        width: 26% !important;
      }
      .hover-front{
        width: 26% !important;
      }
    }
  }

  .component-case-copy-wrapper {
    margin-top: 0;
  }
  
  .component-header-inner-case .sub .foot-image {
    left:0;
    max-width:200px;
    bottom:-200px;
  }

  .video-with-btn{
    position: relative;

    img{
      position: absolute;
      right: -20px;
      bottom: -50px;
      width: 25%;
      z-index: -1;
      max-width: 400px;
    }

    .component-video-wrap video {
      border-radius: 20px;
    }
    a {
      display: inline-block;
      color: #fff;
      font-weight: bold;
      background: #2C2B2D;
      text-transform: capitalize;
      line-height: 100%;
      letter-spacing: 1px;
      font-weight: bold;
      transition: .6s;
      padding: 0;
      padding-bottom: 5px;
      margin-top: 40px;
      font-family: "helveticaNeueLTPro73", sans-serif;
      font-size: 15px;
      font-size: 1.5rem;
      padding: 20px 35px 15px 35px;

      &:hover{
        background: #01136F;
        transition: .3s ease-in-out;
      }
    }
  }

  @media (max-width:$large-tablet){
    .component-header-inner-case .sub .foot-image{
      max-width: 200px;
    }

    .video-with-btn{
      .component-video-wrap{
        padding: 0 40px;
      }
      img{
        right: -7px;
        bottom: 11px;
        max-width: 200px;
      }
    }
  }

  @media (max-width:$mobile){
    .video-with-btn{
      .component-video-wrap{
        padding: 0 20px;
      }
      img{
        right: -5px;
        bottom: 60px;
      }
      a{
        font-size: 1rem;
      }
    }
  }
}

