@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';


.pridewa-wrapper {
	.component-header-inner-case .sub .foot-image {
		@media(max-width:$mobile){
			max-width:150px;
		}
	}
	.component-case-images-gifs-wrapper {
		@media(max-width:$mobile){
			max-width:100%;
			img {
				max-width:initial;
			}
		}
	}
}