@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.curtin-uni-wrapper {
	.component-header-inner-case .sub .foot-image {
		@media(max-width:$mobile){
			max-width:150px !important;
		}
	}
	.component-header-inner-case .parallax-one {
		@media(max-width:$mobile){
			padding-right:0;
			img {
				right:-25px;
			}
		}
	}
}