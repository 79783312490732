@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';


.cott-village-wrapper {
 
  .decal-mock {
  	.component-full-bleed-image {
  		@media(max-width:$mobile){
  			margin-top:40px !important;
  			transform:scale(1.1);
  		}
  	}
  }
  
}