@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';


.Goldfields-wrapper{

  .component-case-copy-wrapper {
    margin-top: 60px;
		padding-bottom: 60px;
  }

	.component-header-inner-case{
		padding-bottom: 40px;
	}
  
  .component-header-inner-case .sub .foot-image {
    left:0;
  }

  .component-video-wrap video {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
	.video-with-blobs{
		position: relative;

		.component-video-wrap video{
			margin: auto;
			width: 85%;
			border-radius: 10px;
			box-shadow: 0px 28px 30px -2px #323232;
		}

		img {
			&.red-blob{
				position: absolute;
				top: -90px;
				left: -10px;
				width: 40%;
				max-width: 500px;
			}
			&.teal-blob{
				position: absolute;
				bottom: -115px;
				right: 17px;
				width: 50%;
				max-width: 800px;
			}
		}

		@media (max-width:$mobile){
			.component-video-wrap video{
				width: 100%;
			}
			img{
				&.red-blob{
					top: -40px;
    			left: -30px;
				}
				&.teal-blob{
					bottom: -30px;
					right: -30px;
				}
			}
		}
	}

	div.special-card-img{
		position: relative;
		img.special-card-img{
			position: absolute;
			top: -72px;
			left: 52px;
			width: 77%;

			@media (max-width:$large-tablet){
				top: -28px;
				left: 38px;
				width: 80%;
			}
			@media (max-width:$mobile){
				left: 17px;
			}
		}
	}

  .component-case-images-gifs-wrapper {
    width:70%;
    margin:auto;
    img {
      width: 33%;
      padding: 20px;
    }
  }

  .parallax-one {
		text-align:right;
		padding-right:100px;
		max-height:50vh;
		position: relative;

		@media (max-width:$mobile){
			padding-right: 0;
		}
	}

	.big-box{
		.lazyload-wrapper {
			display: flex;
		}
	}


}

