@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.hivo-wrapper {
	.component-case-copy-wrapper{
		margin-top:100px;
	}
	.component-full-bleed-image {
		@media(max-width:$mobile){
			.component-full-bleed-image {
				width:60%;
			}
		}
	}
	.component-header-inner-case .sub .foot-image {
		max-width:200px;
		bottom:-200px;
	}
.special-after-full-image::after {
		@media(max-width:$mobile){
			max-width: 80px !important;
		    max-height: 80px !important;
		    right: -14px !important;
		    top: 285px !important;
		}
	}

	 .special-after-full-image{
	
		&::after {
			content: '';
		    position: absolute;
		    right: 0;
		    top: 0;
		    background-image: url(https://okmg-web-assets-rc.s3-ap-southeast-2.amazonaws.com/Case_studies/HIVO/HIVO_icon_2.svg);
		    width: 100%;
		    max-width: 300px;
		    max-height: 300px;
		    height: 100%;
		    /* background-color: red; */
		    background-size: contain;
		    background-repeat: no-repeat;
		    background-position: center;
		    top: 200px;
		    right: -100px;
		}
	}
	.footer-images.component-case-images-wrapper .stat-wrap {
		@media(max-width:$mobile){
			min-height:250px;
		}
	}
	
}	