@import '../../../../Scss/variables.scss';
@import '../../../../Scss/mixins.scss';
.component-full-bleed-image { 
	width:100%;
	animation:linkIn 1.2s 1;
	animation-fill-mode:forwards;
	animation-delay:.3s;
	position:relative;
	top:45px;
	opacity:0;
	width:100%;
	margin:auto;
	height:auto;
	max-width:calc(100% - 200px);
	background-size:cover;
	background-position:center;

	@media(max-width:$large_tablet){
		width:calc(100% - 25px);
		max-width:calc(100% - 50px);
	}
	img {
		width:100%;
		top:3px;
	}
	a {
		color:$ok_black;
	}

	@keyframes linkIn {
		0%{
			top:45px;
			opacity:0;
		}
		100%{
			top:0;
			opacity:1;
		}
	}
}