.curtin-uni-destination {
	.component-header-inner-case {
		.sub-wrap {
			text-align:center;

		}
		.foot-image {
			bottom:-250px;
		}
	}
	.stat-wrap{
		 background-repeat:no-repeat;
		 background-size:contain !important;
	}
	.footer-images {
		.stat-wrap {
			background-size:cover !important;
		}
	}
}