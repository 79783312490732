@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';

.component-header-inner {
    display: flex;
    align-items: center;
    min-height: 65vh;
    position:relative;
    z-index:0;
    padding-top:50px;
    @media(max-width:$mobile){
        padding-bottom:50px;
    }
    .header-content {
        display:block;
        display:flex;
        @media(max-width:$mobile){
            display:block;
        }
        .sub-one:nth-child(1){
            width:70%;
            @media(max-width:$mobile){
                width:100%;
            }
        }
        .sub-two:nth-child(2){
            width:30%;
            z-index:-1;
        }
        .split-span span {
            line-height: 0;
            letter-spacing: -2px;
        }
    }

    h1 {
	position: relative;
    text-align: left;
    line-height: 90%;
    @include font-size(4.3);
    margin-left: 100px;
    color: #2b2b2b;
    text-transform: initial;
    color:$font-color;
    position:relative;
    color:$font-color;
    line-height:100%;
    margin-bottom:5px;
    letter-spacing:-2.5px;
    @media(max-width:$mobile){
        margin-left:20px;
        width:calc(100% - 40px);
        @include font-size(3.3);
        margin-top:60px;
        z-index: 10;
    }

    }
    p {
        span{
            font-family: $sub-font;
        }
        font-family: $sub-font;
        position: relative;
        color: #2B2C2D;
        width: 70%;
        line-height: 100%;
        margin-bottom: 5px;
        margin-bottom: 100px;
        width: 50%;
        text-align:left;
        margin-left:200px;
        line-height:140%;
        animation:textIn .8s 1;
        animation-fill-mode:forwards;
        opacity:0;
        animation-delay:1.3s;
        top:15px;
        margin-top:45px;
        @include font-size(1);
        @media(max-width:$mobile){
            margin-left:50px;
            width:calc(100% - 100px);
        }
    }
    @keyframes textIn {
        0%{
            top:15px;
            opacity:0;
        }
        100%{
            top:0;
            opacity:1;
        }
    }

    @keyframes innerheaderIn {
        0%{
            opacity:0;
            bottom:40px;
        }
        100%{
            
            bottom:0;
            opacity:1;
        }
    }
}

.component-header-inner.home {
    .header-content {
        .sub-two {
            max-width: 620px;
        }
    }
    svg{
        max-width: 760px;
        max-height: 760px;
    }
    p {
        width: 45%;
        margin-left:180px;
        @media(min-width:1920px){
            width:70%;
        }
        @media(max-width:$mobile){
            margin-left: 50px;
            width: calc(100% - 100px);
        }
    }

}
.component-header-inner.home,
.component-header-inner.agency
{
    svg {
        pointer-events:none;
    }
    .header-content {
        .sub-one:nth-child(1){
            width:90%;
            @media(min-width:1920px){
                width:70%;
            }
            @media(max-width:$mobile){
                width:100%;
            }
        }
        .sub-two:nth-child(2){
            position:absolute;
            width:100%;
            max-width:600px;
            margin-left:calc(100% - 700px);
            top:-0px;
            height:100%;
            @media(min-width:1920px){
                max-width:900px;
            }

            @media(max-width:$large_tablet){
               position: absolute;
                top: 0;
                margin-left: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                /* height: 100%; */
                /* max-width: 200px; */
                margin-top: 50px;
                margin-left: 0%;
                width:100%;
                opacity:.3;
            }
        }
       
    }
   
}
