@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.ecc-wrapper {
	@media(max-width:$mobile){
		overflow-x:hidden;
	}
	.component-case-copy-wrapper{
		@media (max-width:$tablet){
			margin-top:80px;
		}
	}
	.component-full-bleed-image {
		@media(max-width:$mobile){
			margin-top:100px !important;
		}
	}
	.component-case-images-gifs-wrapper img {
		@media(max-width:$mobile){
			&:nth-child(1)
			{
				margin-bottom:50px;
				max-width:50%;
			}
			&:nth-child(3) {
				margin-top:50px;
				max-width:50%;
			}
		}
	}
	.component-case-stats-wrapper .stat-wrap h4,
	.component-case-stats-wrapper .stat-wrap p
	 {
		color: #ffffff;
		@media(max-width:$mobile){
			font-size:16px !important;
		}
	}
	.parallax-one {
		text-align:right;
		padding-right:100px;
		display:flex;
		align-items:center;
		justify-content: center;
		.sub-wrap {
			height:0;
			margin-top:-200px;
			@media(max-width:$large-tablet){
				height:auto;
				margin-top:0;
			}
		}
		@media(max-width:$large-tablet){
			padding-top:200px;
			margin-bottom:-300px;
		}
		@media(max-width:$mobile){
			padding-right:0;
			max-height:initial;
			padding-bottom:50px;
			margin-bottom:0;
			padding-top:0;
		}
	}
	img.special.header-image{
		position:relative;

		border-radius:25px;
		-webkit-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		width:auto !important;
		height:33vh;
		@media(max-width:$mobile){
			top:0 !important;
			margin-top:50px;
			width:100%;
			max-width:100%;
			
		}
	}
	img.special.header-image:hover {
		position:relative;
		top:auto;
		
	}
	img.special.header-image:nth-child(1){
		top:-80px;
	

	}
	
	img.special.header-image:nth-child(2){
		margin-right:150px;
		top:-150px;

	}
	img.special.header-image:nth-child(3){
		top:-300px;

	}
	.component-case-stats-wrapper .has_image.stat-wrap img.image-left {
		left:auto;
		right:-50px;
	}
	.component-case-stats-wrapper {
		@media(max-width:$mobile){
			max-width:100%;
		}
		.stat-wrap {
			position:relative;
			
			h4 {
				width:calc(100% - 150px);
				@include font-size(1.5);
				line-height:140%;
				margin:auto;
				margin-bottom:50px;
				@media(max-width:$mobile){
					width:100%;
					margin:0px;
					margin-bottom:25px;
				}
			}
			p {
				@include font-size(1);
			}
			 img.image-left {
			 	width:450px;
			 	z-index:4;
			 }
		}
		.stat-wrap:nth-child(1){
			&::after {
				content:'';
				width:50px;
				position:absolute;
				height:100%;
				background:rgb(10, 78, 132);
				left:-50px;

			}
		}
		.stat-wrap:nth-child(2){
			&::after {
				content:'';
				width:50px;
				position:absolute;
				height:100%;
				background:rgb(10, 78, 132);
				right:-50px;

			}
		}
	}
}