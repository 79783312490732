@font-face{
    font-family:avantegardepro;
    src:url('./../Media/Fonts/avantgardgepro.otf');
}
@font-face{
    font-family:helveticaNeueLTPro73;
    src:url('./../Media/Fonts/HelveticaNeueLTPro-BdEx.otf');
}
@font-face{
    font-family:helveticaNeueLTPro53;
    src:url('./../Media/Fonts/HelveticaNeueLTPro-Ex.otf');
}
@font-face{
    font-family:gothicBook;
    src:url('./../Media/Fonts/avanteBooker.ttf');
}

/*
h1: Helvetica Neue LT Pro - 73 Bold Extended
h2: Helvetica Neue LT Pro - 73 Bold Extended
h3: Helvetica Neue LT Pro - 73 Bold Extended
h4: Helvetica Neue LT Pro - 73 Bold Extended or 53
h5: Helvetica Neue LT Pro - 53
copy: Helvetica Neue - Light. All body copy shall be this size, including any hyperlinks within or outside of the parahgraph in which case are to be underlined.
*/

$heading-font: 'helveticaNeueLTPro73', sans-serif;
$sub-heading-font: "helveticaNeueLTPro53", sans-serif;
$sub-font: 'Helvetica Neue', sans-serif;
// $heading-font: 'helveticaNPro','avantegardepro', "Raleway","Helvetica", arial, sans-serif;
// $sub-heading-font: "helveticaNProLight", 'Helvetica Neue','Helvetica','gothicBook', 'helvetica', arial, sans-serif;
// $sub-font: 'Helvetica Neue','Helvetica','gothicBook', 'helvetica', arial, sans-serif;

$primary: #1675A9;
$secondary: #FF8672;
$default: #fff;

$font-color:#2B2C2D;

$ok_purple: #7C2982;
$ok_blue: 	#4A81E9;
$ok_light_blue: #43B7E9;
$ok_white: #fff;

$ok_fade_blue: #50ABB9;
$ok_green:	#21B14B;
$ok_orange: #EBBD1D;
$ok_red: 	#E62E2E;
$ok_black: 	#2C2B2D;
$white:#fff;

$small_desktop: 1300px;
$large_tablet: 1100px;
$tablet: 768px;
$mobile: 650px;