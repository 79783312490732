@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.perthfestival-wrapper {
	@media(max-width:$mobile){
		overflow-x:hidden;
	}
	.stat-wrapper {	
		display: inline-flex;
    position: relative;
    justify-content: flex-end;
    width: 100%;
    padding: 200px 0;
    // margin: 100px 0 0;
    min-height: 400px;
		
		.video-wrap {
			position: absolute;
			left: 50px;
			top: 50%;
			transform: translateY(-50%);
			z-index: 88;
			.left-vid {
				max-height: 600px;
				border-radius: 40px;
			}
		}
		.insta-wrap {
			position: absolute;
			z-index: 88;
			left: 360px;
			top: 147px;
			max-width: 600px;
			.insta-svg {
				width:400px;
			}
			.insta-vid {
				position: absolute;
				left: 70px;
				top: 103px;
				width: 260px;
			}

		}
		@media screen and (max-width:1200px){
			padding: 100px 0;
			.video-wrap {
				left: 24px;
				.left-vid {
					max-height: 460px;
				}
			}
			.insta-wrap {
				max-width: 400px;
				left: 240px;
				top: 58px;
				.insta-svg {
					width: 360px;
				}
				.insta-vid {
					position: absolute;
					left: 62px;
					top: 92px;
					width: 235px;				
				}
			}
		}

		.component-case-stats-wrapper{
			margin-top:0;
			margin: auto;
			.stat-wrap{
				min-height: 300px;
				padding: 0 25px;
				justify-content: flex-end;
				border-radius: 0 40px 40px 0;
				span.stat-sub-wrap {
					width: 50%;
					color: #ffffff;
					h4{
						font-size: 1rem;
						line-height: 120%;
					}
					p{
						font-size: 1rem;
						padding-top: 20px;
						color:#ffffff;
					}
				}
				
			}
		}
		@media (max-width:$tablet){
			display: flex;
			flex-wrap: wrap;
			position: relative;
			justify-content: space-evenly;
			width: 100%;
			padding: 100px 0 0;
			min-height: 400px;

			.video-wrap{
				position: relative;
				transform: translateY(0);
				left:0;
			}
			.insta-wrap {
				position: relative;
				z-index: 88;
				left: 0;
				top: 0;
				width: auto;
				.insta-vid {
					position: absolute;
					left: 64px;
					top: 92px;
					width: 231px;
				}
			}
			.component-case-stats-wrapper{
				max-width: 100%;
				.stat-wrap {
					min-height: 200px;
					padding: 30px 25px;
					border-radius: 0px;
					span.stat-sub-wrap {
						width: 100%;
					}
				}
			}
		}

	}
	
	.component-case-copy-wrapper {
		margin-top:0;
	}
	.component-header-inner-case .sub .foot-image {
		bottom:-150px;
		@media (max-width:$large-tablet){
			bottom:-48px;
		}
		@media (max-width:$tablet){
			bottom:-16px;
		}
		@media (max-width:650px){
			bottom:-20px;
		}
	}

	.parallax-one {
		text-align:right;
		padding-right:100px;
		max-height:50vh;
		@media(max-width:$mobile){
			padding-right:0;
			max-height:initial;
			padding-bottom:50px;
		}
	}
	img.special.header-image{
		position:relative;

		border-radius:25px;
		width:auto;
		height:calc(33vh - 33px);
		-webkit-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		@media(min-width:1921px){
			max-height:350px !important;
		}
		@media(max-width:$mobile){
			top:0 !important;
			margin-top:50px;
			width:100%;
			max-width:100%;
			
		}
	}
	img.special {
		-webkit-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
	}
	img.special.header-image:hover {
		position:relative;
		top:auto;
		
	}
	img.special.header-image:nth-child(1){
		top:-80px;

	}
	
	img.special.header-image:nth-child(2){
		margin-right:150px;
		top:-150px;
	}
	img.special.header-image:nth-child(3){
		top:-300px;
	}
	.component-case-images-gifs-wrapper {
		flex-wrap:wrap;
		width:70%;
		margin:auto;
		img {
			margin:15px;
		}
		img:nth-child(1){
			box-sizing:border-box;
			padding:90px;
			@media(max-width:$large_tablet){
				padding:50px;
			}
			@media(max-width:$mobile){
				padding:0;
			}
		}
		img:nth-child(4){
			box-sizing:border-box;
			padding:150px;
			@media(max-width:$large_tablet){
				padding:100px;
			}
			@media(max-width:$mobile){
				padding:50px;
			}
		}
	}

}
