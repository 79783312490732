@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.rise-wrapper {
	img.special-stat-image {
		margin:25px;
		-webkit-box-shadow: 0px 0px 68px -24px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 68px -24px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 68px -24px rgba(0,0,0,0.75);
	}
	.component-header-inner-case .sub .foot-image {
		@media(max-width:$mobile){
			max-width:150px !important;
		}
	}

	.special-vid{
		position: relative;
		.component-video-wrap::after {
			content: '';
			position: absolute;
			/* width: 100%; */
			right: 5%;
			height: 100%;
			border-right: 60px solid #fff;
		}
	}
	@media (max-width:$mobile){
		.special-vid{
			display: none;
		}
	}


	.parallax-one {
		text-align:right;
		padding-right:100px;
		position: relative;

		.header-video{
			position: relative;
			margin: 0;
			.video-bg{
				position: relative;				
			}
			.inner-video{
				position: absolute;
			}
		}
		.header-video:nth-child(1){
			top: -20px;
			right: -50px;
			.video-bg{
				height:105%;	
				width: 105%;	
			}
			.inner-video{
				width: 100%;
				left: 0;
				top: 0;
				height: 100%;
				position: absolute;
				video {
					height: 100%;
					width: 88%;
					top: -11%;
					left: 6%;
					position: absolute;
				}
			}
		}

		img:nth-child(2){
			max-width: 270px;
			height: 130px;
			position: absolute;
			left: 43px;
			top: 258px;
		}
		img:nth-child(3){
			position: absolute;
			transform: rotate(-90deg);
			max-width: 340px;
			left: 0;
			top: 136px;
		}

		@media(max-width:$large-tablet){
			padding-right:0;
			.header-video:nth-child(1){
				top: 0px;
				right: 0px;
				width: 80%;
				margin: auto;
			}
			.header-video:nth-child(2){
				right: 50%;
				top:-100px;
				transform: translateX(43%);
				width: 50%;
			}
			img:nth-child(2){
				left:142px;
				top:440px;
			}
			img:nth-child(3){
				left:102px;
				top:321px;
			}
		}
		@media(max-width:$tablet){
			.header-video:nth-child(1){
				width:100%;
			}
		}
		@media(max-width:$mobile){
			padding-right:0;
			max-height:initial;
			margin-top: 20px;
			margin-bottom: 50px;

			img:nth-child(2){
				left:26px;
				top: 234px;
				max-width: 197px;
				height: 96px;
			}
			img:nth-child(3){
				left:0px;
				top: 149px;
				max-width: 240px;
			}
		}
		@media (max-width:400px){
			img:nth-child(3){
				left:0px;
				top: 100px;
				max-width: 160px;
			}	
		}
	}

}