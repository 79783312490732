@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';
.laptop-wrapper {
    position: relative;
    margin-top: 50px;
    span {
    	position:absolute;
    	width:100%;
    	height:50%;
    	background:#d20f38;
    	z-index:-1;
    	top:10%;
    }

}
.sub {
    margin-bottom:50px ;
}
.hair-supplies-wrapper {
    .switch {
        z-index:9;
    }

    .component-full-bleed-image img {
        @media(max-width:$mobile){
            left:0 !important;
        }
    }
    .component-header-inner-case .parallax-one.sux:after {
        position: absolute;
        content: "";
        width: 100%;
        top: 0;
        left: 0;
        /* background: red; */
        height: 150px;
        bottom: -50px;
        top: auto;
    }

    .component-header-inner-case .header-image {
        width:120%;
        top:-50px;
    }
    .component-header-inner-case .header-image:nth-child(2){
        position:absolute;
    }
    .special-image-one.stat-wrap {
        margin-bottom: 100px;
    }
    .image-absolute.stat-wrap {
        width: 100%;
        height: 100%;
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        background-size: 400px;
    }
    .component-case-images-gifs-wrapper {
      .special
        {
            -webkit-box-shadow: 0px 0px 68px -30px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 68px -30px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 68px -30px rgba(0,0,0,0.75);
        }

    }
    .footer-images.component-case-images-wrapper .stat-wrap {
        @media(max-width:$mobile){
            min-height:250px;
        }
    }
     .special-image.stat-wrap {
            @media(max-width:$mobile){
                background-size:contain !important;
                height: 250px;
                min-height: auto;
            }
        }
        .custom-height-mob.stat-wrap {
            @media(max-width:$mobile){
                height: 250px;
                min-height: auto;
            }
            
        }
        .special {
            &:nth-child(2){
                margin-top:55px;

            }
        }
}
