@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.revo-fitness-wrapper {
    .component-header-inner-case .sub .foot-image {
        max-width:200px;
        bottom:-200px;
        @media(max-width:$mobile){
            max-width:100px;
            bottom:-0px;
            opacity:.1 !important;
        }
    }
}
.laptop-wrapper {
    position: relative;
    margin-top: 50px;
    span {
    	position:absolute;
    	width:100%;
    	height:50%;
    	background:#d20f38;
    	z-index:-1;
    	top:10%;
    }
}