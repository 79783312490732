@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';

.component-home-case-studies-main-wrap {
	.heading {
		@include font-size(2);
		font-family: $heading-font;
		text-align:left;
		margin-left:100px;
		font-weight:lighter;
		@media(max-width:$mobile){
			margin-left:15px;
		}
	}
}

.component-home-case-studies {
	display:flex;
	flex-wrap:wrap;
	width:100%;
	animation:caseStudiesIn 1.2s 1;
	animation-fill-mode:forwards;
	animation-delay:.3s;
	position:relative;
	top:45px;
	opacity:0;
	width:100%;
	margin:auto;
	@media(max-width:$large_tablet){
		width:calc(100% - 25px)
	}

	.hover-video {
        object-fit: fill;
        position: absolute;
        left: 0;
        width: auto;
        height: 100%;
        z-index:1;
        opacity:0;
    }

	span.hover-image {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    z-index: 0;
	    background-size: cover;
	    background-position: center;
	    opacity:1;
	    transition:0s;
	}
	h3 {
		@include font-size(.9);
	}
	.sub:hover span.hover-image {
		transition:1.2s;
	}
	.case-bg-image {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background-size:cover;
		background-position:center;
		transition:0s;
	}
	.logo {
		transition:.4s;
	}
	.sub:hover .logo {
		opacity:0;
		transition:.5s;
		top:-4vh;
	}

	@keyframes caseStudiesIn {
		0%{
			top:45px;
			opacity:0;
		}
		100%{
			top:0;
			opacity:1;
		}
	}
	a {
		color:$ok_black;
		width:100%;
		display:flex;
		align-items:center;
		justify-content: center;
		height:100%;
		position:absolute;
		left:0;
		top:0;

	}
	video {
		-o-object-fit: fill;
		object-fit: fill;
	}
	div.sub-one {
		width:calc(33.33% - 50px);
		display:flex;
		> div.bg-image {
			height:100%;
			width:100%;
			margin:auto;
			height:100vh;
			h2 {
				margin-top:0;
				@include font-size(10);
			
			}
		}
	}
	div.sub-two,div.sub-three {
		
		
		height:60vh;
	}
	div.sub-four {
		height:45vh;
	}

	div.sub-two {
		width:calc(50% - 50px);
		margin-left:50px;
		height:80vh;
		&:nth-child(2){
			margin-left:0;
		}
		@media(max-width:$large_tablet){
			margin-left:15px;
			width:calc(50% - 15px);
		}
		@media(max-width:$mobile){
			margin-left:15px;
			margin-right:15px;
			width:100%;
			height:350px;
			&:nth-child(2){
				margin-left:15px;
				margin-right:15px;
			}
		}
	}
	div.sub-three {
		width:calc(33.33% - 33px);
		&:nth-child(1){
			margin-left:50px;
		}
		&:nth-child(3){
			margin-left:0;
		}
		@media(max-width:$large_tablet){
			margin-left:30px;
			margin-right:30px;
			width:100%;
			height:350px;
			&:nth-child(1){
				margin-left:30px;
				margin-right:30px;
			}
			&:nth-child(3){
				margin-left:30px;
			}
		}
		@media(max-width:$mobile){
			margin-left:0px;
			margin-right:0px;
			width:100%;
			height:350px;
			&:nth-child(1){
				margin-left:0px;
				margin-right:0px;
			}
			&:nth-child(3){
				margin-left:0px;
			}
		}
	}
	div.sub-four {
		width:calc(25% - 45px);
		margin-right:50px;
		@media(max-width:1250px){
			width:calc(50% - 45px);
		}
		&:nth-child(1){
			margin-left:90px;
			margin-right:0;
			@media(max-width:$mobile){
			width:100%;
			
			}
			@media(max-width:$mobile){
				margin-left:0;
			}
		}
		&:nth-child(2),
		&:nth-child(3)
			{
			margin-right:0;

			@media(max-width:$mobile){
				margin-left:0;
			}
		}
		&:nth-child(2){
			@media(max-width:1250px){
			width:calc(50% - 130px);
			}
			@media(max-width:$mobile){
				width:100%;
			}
		}
		&:nth-child(3){
			@media(max-width:1250px){
			margin-left:90px;	
			}
			@media(max-width:$mobile){
				margin-left:0;
			}

		}

		&:nth-child(4){
			margin-left:0;
			@media(max-width:1250px){
			width:calc(50% - 130px);
			}
			@media(max-width:$mobile){
				margin-left:0;
				width:100%;
				margin-right:0;
			}
		}
		@media(max-width:$mobile){
			margin-left:15px;
			margin-right:15px;
			width:100%;
			height:350px;
		}
	}
	div {
		background-size:cover;
		position:relative;
		cursor:pointer;
		overflow:hidden;
		margin-bottom:15px;
		background-size:contain;
		background-repeat:no-repeat;
		background-position:0 35px;
		display:flex;
		align-items:center;
		justify-content: center;

		img {
			max-width:100%;
			position:relative;
			top:-6vh;
			z-index:2;
		}
		img.arrow-right {
			top: 15px;
		    margin-left: 15px;
		    width: 20px;
		    position: absolute;
		}
		@media(max-width:$mobile){
			width:100%;
			height:329px;
		}

		&:nth-child(1) .filter-init{
			animation-delay:1.2s;
		}
		&:nth-child(2) .filter-init {
			animation-delay:1.5s;
		}
		&:nth-child(3) .filter-init{
			animation-delay:1.8s;
		}


		.filter {
			position:absolute;
			width:100%;
			height:0;
			transition:.7s;
			left:0;
			z-index:1;
		}
		span.filter-black {
		    position: absolute;
		    width: 100%;
		    height: 100%;
		    background: rgba(0,0,0,0.1);
		    left: 0;
		    z-index:2;
		}	
		span.filter-ok {
		    position: absolute;
		    left: 0;
		    top: 0;
		    width: 0%;
		    height: 100%;
		    background: $ok_orange;
		    transition:.5s;
		    transition-delay:.8s;
		}


		.filter-init {
			height:100%;
			width:100%;
			position:absolute;
			left:0;
			animation:filterInit .6s 1;
			animation-fill-mode:forwards;



		}

		@keyframes filterInit {
			0%{
				height:100%;
			}
			100%{
				height:0;
			}
		}

		h4 {
			color: #fff;
		    box-sizing: border-box;
		    text-align: left;
		    box-sizing: border-box;
		    padding: 0 50px;
		    text-transform:uppercase;
		    position:relative;
		    transition:.5s;
		    line-height:115%;
		    @include font-size(.8);
		    z-index:2;
		    @media(max-width:$mobile){
		    	padding-left:20px;
		    }
		}
		span.client-info {
		    display: block;
		    position: absolute;
		    bottom: 0;
		    width: 100%;
		    background: #fff;
		    text-align: left;
		    padding-top: 0px;
		    left:0;
		    z-index:2;
		    h3 {

			    span {
			    	font-family:$sub_font;
			    	font-weight:normal;
			    }
			}
		}


	}
	.spacer-one {
		width:50px;
		z-index: 1;
    	mix-blend-mode: difference;
    	background:#fff;
	}
	.spacer-two {
		width:50px;
		z-index: 1;
    	mix-blend-mode: difference;
    	background:#fff;
	}
	.tech-slide-wrap {
		position:absolute;
		width:100%;
		height:100%;
		left:0;
		top:0;
		display:flex;
		align-items:center;
		.tech-slideshow {
			display:flex;
			position:absolute;
			.mover-1,.mover-2 {
				animation: marquee 35s linear infinite;
				h2 {
					display:flex;
					@include font-size(15);
					margin-top:0;
					color:#fff;
					span {
						font-weight:bold;
						font-family:$heading-font;
					}
				}
			}
		}
	}
	
	@keyframes marquee {
	  0% {
	    transform: translate(0, 0);
	  }
	  100% {
	    transform: translate(-100%, 0);
	  }
	}
}