@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.pilbara-minerals-wrapper{

    .component-case-copy-wrapper{
        margin-top:100px;
    }

    video {
        &::after {
            content:'';
            width:20px;
            height: 100%;
            background:red;
            right:0%;
            position:absolute;
        }
    }
  .pm-img-full-wrap {
    .component-case-images-gifs-wrapper {
        max-width: 100%;
        img.pm-img {
            width: 100%;
            @media (max-width:$mobile){
                max-width: 100%;
            }
        }
    }
}
.animate-vid-wrap{
    position: relative;
}
.animate-vid-wrap::after {
    content: '';
    height: 100%;
    position: absolute;
    right: 6%;
    width: 5%;
    background: #fff;
    @media (max-width:$large-tablet){
        border-right: 40px solid #fff;
    }
    @media (max-width:$tablet){
        right:6%;
    }
    @media (max-width:$mobile){
        border-right: 30px solid #fff;
    }
}
}