@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';


.RFDS-wrapper{

  .component-case-copy-wrapper {
    margin-top: 60px;
  }

	.component-header-inner-case{
		padding-bottom: 40px;
	}
  
  .component-header-inner-case .sub .foot-image {
    left:-50px;
		max-width:300px;
		bottom:-150px;
		@media(max-width:$tablet){
			bottom:-200px;
		}
  }

	@media (max-width:$mobile){
		.component-header-inner-case .sub{
			padding-right:0;
		}
		.component-header-inner-case .sub .foot-image{
			max-width: 100%;
			left: 0;
			margin-top: 40px;
			margin-bottom:  20px;
		}
		.component-case-images-gifs-wrapper img{
			max-width: 100%;
		}
	}

	.component-case-copy-wrapper .sub.sub-one:first-child{
		display: none;
	}

  .component-video-wrap video {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .component-case-images-gifs-wrapper {
    width:70%;
    margin:auto;
    img {
      width: 33%;
      padding: 20px;
    }
  }

  .parallax-one {
		text-align:right;
		padding-right:0;
		max-height:50vh;
		position: relative;

		@media (max-width:$large-tablet){
			img{
				margin-right: -25px;
			}
		}
		@media (max-width:$mobile){
			padding-right: 0;
		}
	}

	.big-box{
		.lazyload-wrapper {
			display: flex;
		}
	}

	.rect-images {
    width: 100%;
    max-width: calc(100% - 200px);
    margin: auto;
		.stat-wrap{
			display: flex;
			width: 100%;

			.img1,.img2{
				width: 50%;
				display: block;
				img{
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}

		@media (max-width:$large-tablet){
			width: calc(100% - 25px);
			max-width: calc(100% - 50px);
		}
		@media (max-width: $mobile){
			.stat-wrap{
				flex-direction: column;
				.img1,.img2{
					width: 100%;
					display: flex;
				}
			}
		}
	}
}

