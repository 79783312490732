@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';

.component-services-box {
    
    color: $ok_black;
    display: flex;
  	height:calc(100vh - 50px);
    text-align:left;
    box-sizing:border-box;
    align-items:center;
    margin-left:50px;
    margin-right:48px;
    position:relative;
    height:auto;
    @media(max-width:$mobile){
    	height: auto;
	    width: 100%;
	    margin-left: 25px;
	    margin-right: 25px;
	    width: calc(100% - 50px);
	    box-sizing: border-box;
    }

    video {
        width:100%;
        z-index:1;
        cursor:pointer;
    }

    svg {
        transform: scale(1.5) !important;
        position: relative;
        bottom: 0;
    }
    .sub-one {
    	width:calc(100% - 50px);
    	padding: 0px 50px;
    	height:100%;
    	display:flex;
    	align-items:center;
    	@media(max-width:$mobile){
    		width:100%;
            padding:0;
            padding-bottom:15px;
    	}
        .sub-sub-one {
            width:45%;
            p {
            	padding-left:100px;
            	width:calc(100% - 100px);
            	@media(max-width:$mobile){
            		padding-left:25px;
            		width:calc(100% - 25px);
            	}
            }
            @media(max-width:$large_tablet){
                width:100%;
            }
        }
        .sub-sub-two {
            width:55%;
            display:flex;
            align-items:center;
            justify-content: center;
            @media(max-width:$large_tablet){
                display:none;
            }
            > div {
                overflow:visible !important;
            }
            img {
                max-width:100%;
                filter:invert(1);
            }
        }
    	h2 {
    		line-height:100%;
    		font-family:$heading_font;
    		font-weight:bold;
    		text-transform:initial;
            @include font-size(3);
    	
    	}
    	p {
    		width:100%;
    		@include font-size(1);
    		margin-bottom:0px;
    		@media(max-width:$mobile){
    			width:100%;
    		}
    	}

    }
    .sub-two {
    	width:50px;
    	background:#fff;
    	height:100%;
    	z-index:4;
    	mix-blend-mode: difference;
    }
    .tech-slide-wrap {
		position:absolute;
		width:50%;
		height:100%;
		left:0;
		top:0;
		display:flex;
		margin-left:50%;
		align-items:center;
		.tech-slideshow {
			display: flex;
		    position: absolute;
		    bottom: 0;
		    right: 0;
		    overflow: hidden;
		    width: 100%;
		    height: 43px;
		    border-left: 1px solid #fff;
		    margin-bottom: 50px;
			.mover-1,.mover-2 {
				animation: marquee 35s linear infinite;
				h6 {
					display:flex;
					@include font-size(2);
					margin-top:0;
					color:#fff;
					span {
						font-weight:bold;
						font-family:$heading-font;
					}
				}
			}
		}
	}
	@keyframes marquee {
	  0% {
	    transform: translate(0, 0);
	  }
	  100% {
	    transform: translate(-100%, 0);
	  }
	}
}