@import '../../../../Scss/variables.scss';
@import '../../../../Scss/mixins.scss';

.component-header-inner-case {
	display: flex;
    align-items: center;
    min-height: 80vh;
    position:relative;
    z-index:5;
    padding-top:50px;
    @media(max-width:$large_tablet){
        padding:0 25px;
        padding-top:100px;
        margin-bottom:25px;
        flex-wrap:wrap;
    }
    span.byline {
        display: block;
        font-size: 45px;
        font-family:$heading_font;
        color: $ok_black;
        line-height: 105%;
        align-items: top;
       animation:bylineIn .6s 1;
       animation-fill-mode:forwards;
       position:relative;
       opacity:0;
       animation-delay:.95s;
    }
    @keyframes bylineIn {
        0%{
            opacity:0;
            left:-15px;
        }
        100%{
            opacity:1;
            left:0;
        }
    }

    img.header-image.hover-front,
    img.header-image.hover-back
     {
        transition:.5s;
        &:hover {
            transition: .6s;
            position: absolute;
            cursor: pointer;
            animation-fill-mode:forwards;
            top:auto;
            top:-130px;
            transition:.5s;

        }

    }
    .multi-image-wrap img {
        -webkit-box-shadow: 0px 0px 62px -32px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 62px -32px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 62px -32px rgba(0,0,0,0.75);
        margin-right:15px;
        cursor:pointer;
        transition:.5s;
        &:hover {
            transform:scale(1.2);
            transition:.9s;
            z-index:4;
            -webkit-box-shadow: 0px 0px 62px -8px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 62px -8px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 62px -8px rgba(0,0,0,0.75);
        }
    }
   
    .sux {
        position:relative;
        animation:RightCIn 1s 1;
        animation-fill-mode:forwards;
        animation-delay:1s;
        opacity:0;
        right:-15px;
    }
    @keyframes RightCIn {
        0%{
            opacity:0;
            right:-15px;
        }
        100%{
            opacity:1;
            right:0;
        }
    }
    .header-video {
        width:100%;
        margin-left:75px;
        margin-top:180px;
        @media(max-width:$large_tablet){
            margin-left:0;
            margin-top:0;
        }
    }
    .header-image {
        right:0px;
        width:100%;
        @media(max-width:$large_tablet){
            position:relative;
            bottom:0;
            width:100% !important;
        }
    }
   
    h1 {
    	position: relative;
        text-align: left;
        line-height: 90%;
        @include font-size(5);
        margin-left: 100px;
        color: #2b2b2b;
        text-transform: initial;
        color:$font-color;
        position:relative;
        color:$font-color;
        line-height:100%;
        margin-bottom:5px;
        letter-spacing:-2.5px;
        margin-top:0;
        @media(max-width:$mobile){
            margin-left:0px;
            @include font-size(3.2);
             margin-top:60px;
             width:100%;
        }

    }
    h4 {
    	font-family:$sub-font;
    	@include font-size(2);
    	font-weight:lighter;
        line-height:100%;
    }
    h5 {
    	@include font-size(2);
    	margin-top:21px;
    	margin-left:50px;
        letter-spacing:-1px;
        @media(max-width:$large_tablet){
            margin-left:0;
            margin-bottom:50px;
            margin-top:100px;
        }
    }
    .sub-two {
    	animation:infoIn .8s 1;
    	animation-fill-mode:forwards;
    	top:25px;
    	position:relative;
    }
    @keyframes infoIn {
    	0%{
    		opacity:0;
    		top:15px;
    	}
    	100%{
    		opacity:1;
    		top:0;
    	}
    }
    .sub-one-sub.one {
        padding-top:50px;
        @media(max-width:$mobile){
            padding-top:0;
        }
    }
    .sub-one-sub.one, 
    .sub-two-sub.one {
        height:100%;
       
        @media(max-width:$large_tablet){
            height:auto;
            max-height:inherit;
        }

	}
	.sub-one-sub.one p {
		margin-left:200px;
        @include font-size(1);
        position:relative;
        animation:pIn .6s 1;
        animation-fill-mode:forwards;
        opacity:0;
        animation-delay:.6s;
        font-family:$sub-heading-font;
        @media(max-width:$mobile){
            margin-left:5px;
        }
	}  
    @keyframes pIn {
        0%{
            top:15px;
            opacity:0;
        }
        100%{
            top:0;
            opacity:1;
        }
    }
    .sub-one-sub.one  p.service-info {
        @include font-size(1.3);
        color:#c1c1c1;
        z-index:2;
        margin-top:45px;
        font-family:$sub-heading-font;
    }

    .sub {
    	width:50%;
    	text-align:left;
    	padding-right:25px;
    	box-sizing:border-box;
        position:relative;
        .foot-image {
            
            bottom: -50px;
            left: -50px;
            max-width: 200px;
            bottom:-200px;
            position: absolute;

            @media(max-width:$large_tablet){
                max-width:200px;
                bottom:-150px;
                left:-25px;
                opacity:.3 !important;
            }
            @media(max-width:$mobile){
                display:none !important;
            }

        }
        @media(max-width:$large_tablet){
            width:100%;
        }

    }
    .parallax-one {
        width:50%;
        text-align:left;
        padding-right:25px;
        box-sizing:border-box;
        @media(max-width:$large_tablet){
            width:100%;
            margin-top:0;
        }
    }
}