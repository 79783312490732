@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';

.split-span {
	line-height:70%;
	
	span {
		font-family:$heading_font;
		line-height:0;
		letter-spacing: -2px;
		color:$ok_black;
		position:relative;
		display:inline;
		transform:rotate(20deg);
		animation:spanIn .5s 1;
		animation-fill-mode:forwards;
		opacity:0;
		top:15px;
		animation-delay:.6s;
		letter-spacing:-2px;
	}
	@for $i from 1 through 100 {
	  span:nth-child(31n + #{$i}) {
	   	animation-delay: $i * 0.05s;
	  }
	}
	@keyframes spanIn {
		0%{
			opacity:0;
			top:15px;
		}
		100%{
			opacity:1;
			top:0;
		}
	}
}