@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.StudyPerth-wrapper {
	.component-case-copy-wrapper{
		@media (max-width:$tablet){
			margin-top:160px;
		}
	}
	.parallax-one {
		text-align:right;
		padding-right:100px;
		padding-top:100px;
		@media(max-width:$mobile){
			padding-right:0;
			max-height:initial;
			padding-bottom:50px;
			padding-top:0;
		}
	}
	img.special.header-image{
		position:relative;

		border-radius:25px;
		width:100%;
		width:auto;
		-webkit-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		height:calc(33vh);
		@media(max-width:$mobile){
			top:0 !important;
			margin-top:50px;
			width:100%;
			max-width:100%;
			
		}
	}
	img.special {
		-webkit-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
	}
	img.special.header-image:hover {
		position:relative;
		top:auto;
		
	}
	img.special.header-image:nth-child(1){
		top:-80px;
		@media(max-width:$large-tablet){
			top:0;
			margin-bottom:15px;
			max-width:500px;
		}

	}
	
	img.special.header-image:nth-child(2){
		margin-right:150px;
		top:-200px;
		@media(max-width:$large-tablet){
			top:0;
			right:-100px;
			margin-bottom:15px;
			max-width:500px;
		}
		@media(max-width:$mobile){
			right:0;
		}
	}
	img.special.header-image:nth-child(3){
		top:-300px;
		@media(max-width:$large-tablet){
			top:0;
			margin-bottom:15px;
			max-width:500px;
		}
	}
	.component-case-images-gifs-wrapper {
		flex-wrap:wrap;
		width:70%;
		margin:auto;
		img {
			margin:15px;
		}
		img:nth-child(1){
			box-sizing:border-box;
			padding:90px;
			@media(max-width:$large_tablet){
				padding:50px;;
			}
			@media(max-width:$mobile){
				padding:0;
			}
		}
		img:nth-child(4){
			box-sizing:border-box;
			padding:150px;
			@media(max-width:$large_tablet){
				padding:100px;
			}
			@media(max-width:$mobile){
				padding:50px;
			}
		}
	}
}
