@import '../../../../Scss/variables.scss';
@import '../../../../Scss/mixins.scss';

.component-case-images-gifs-wrapper {
    display: flex;
    width: 100%;
    margin-top: 60px;
    max-width: calc(100% - 200px);
    margin: auto;
    margin-top: 0;
    align-items:center;
    justify-content:center;
    @media(max-width:$large_tablet){
        max-width:calc(100% - 50px);
        flex-wrap:wrap;
    }
    img {
      @media(maxw-width:$large_tablet){
        
      }
      @media(max-width:$mobile){
        width:100% !important;
        max-width:250px;
      }
    }
    .stat-wrap {
      width:100%;
      box-sizing:border-box;
      padding:25px;
      color:$ok_white;
      min-height:400px;
      display:flex;
      align-items:center;
      justify-content:center;
        background-size:cover;
        background-position:center;
   
        @media(max-width:$large_tablet){
            width:100% !important;
            background-position:center !important;
            background-size:70% !important;
        }
      h4 {
        @include font-size(6);
        margin-bottom:25px;
        margin-top:20px;
    }
    p {
      font-family:$heading_font;
      @include font-size(3);
      margin-top:0;
      line-height:110%;
      margin-bottom:0;
    }

    }
}