@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.Primero-wrapper {
	.component-full-bleed-image img {
		@media(max-width:$mobile){
			left:0 !important;
		}
	}
	.component-header-inner-case {
		
		.header-video {
			margin-top:0;
			width:105%;
			height:105%;
			margi-left:0;
			@media(max-width:$mobile){
				margin-top:100px !important;
			}
		}
	}
	.parallax-one{
		.header-video{

			@media(max-width:$mobile){
				margin:0;
				margin-top:100px;
			}
		}
	}
	// .parallax-one{
	// 	text-align:right;
	// 	padding-right:100px;
	// 	max-height:50vh;
	// 	position: relative;
	// 	.header-video{
	// 		width: 800px;
	// 		height: 600px;
	// 		margin:0;
	// 	}
	// }
	// @media (max-width:$large-tablet){
	// 	.parallax-one{
	// 		padding: 0;
	// 		width: 100%;
	// 		.header-video {
	// 			width: 100%;
	// 			height: auto;
	// 		}
	// 	}
	.component-case-copy-wrapper{
			margin-top:100px;
	}
	
	.special-video-primero {
		border-radius:25px;
		-webkit-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
	}
	span.stat-sub-wrap {
	    padding-left: 250px;
	    line-height:140%;
	    @media(max-width:$mobile){
	    	padding-left:0;
	    }
	    h4 {
	    	@include font-size(1.6);
	    	line-height:120%;
	    	@media(max-width:$mobile){
	    		@include font-size(1);
	    	}
	    }
	    p {
	    	@include font-size(1);
	    	font-weight:100;
	    }
	}
}