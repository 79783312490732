@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';


.Femma-wrapper{

  .component-case-copy-wrapper {
    margin-top: 60px;
  }
	.component-header-inner-case .sub .foot-image {
		left:0;
		max-width: 250px;
		bottom:-350px;

	}

	.component-header-inner-case{
		.parallax-one{
			.image-with-icon{
				position: relative;
				img.header-image-icon {
					position: absolute;
					top: -7px;
					right: 42px;
					z-index: 10;
					width: 18%;
					max-width: 160px;
					-webkit-animation: rotate 50s linear infinite;
					-moz-animation: rotate 50s linear infinite;
					animation: rotate 50s linear infinite;
				}
			}
		}
	}
	
	@media (max-width:$large-tablet){
		.component-header-inner-case .sub .foot-image {
			left:-25px;
			max-width: 300px;
		}
	}
	@media (max-width:$mobile){
		.component-header-inner-case{
			.parallax-one{
				.image-with-icon{
					img.header-image-icon {
						position: absolute;
						top: -17px;
						right: 0;
						-webkit-animation: rotate 50s linear infinite;
						-moz-animation: rotate 50s linear infinite;
						animation: rotate 50s linear infinite;
					}
				}
			}
		}
		.component-header-inner-case .sub{
			padding-right: 0;
		}
		.component-full-bleed-image img{
			max-width: 100% !important;
		}
		.component-header-inner-case .sub .foot-image {
			max-width: 250px;
		}
	}
  

	.component-video-wrap .lazyload-wrapper {
		display: flex;
	}

  .component-case-images-gifs-wrapper {
    width:70%;
    margin:auto;
    img {
      width: 33%;
      padding: 20px;
    }
  }

  .parallax-one {
		text-align:right;
		padding-right:100px;
		max-height:50vh;
		position: relative;
		margin-top: -100px;

		@media (max-width:$large-tablet){
			margin-top: 0;
		}
		@media (max-width:$mobile){
			padding-right: 0;
			img{
				transform: scale(1.2) translateX(-10px);
			}
		}
	}

	div.big-box{
		position: relative;

		img.big-box{
			position: absolute;
			right: 200px;
			top: 15%;
			width: 100%;
			max-width: 350px;
			z-index: 99;
			-webkit-animation: rotate 50s linear infinite;
			-moz-animation: rotate 50s linear infinite;
			animation: rotate 50s linear infinite;

			@media (max-width:$large-tablet){
				right: 120px;
				max-width: 150px;
			}
			@media (max-width:$mobile){
				display: none;
			}
		}

		.component-full-bleed-image{
			img{
				width: 60%;
				margin-left: 0;
				margin-right: 490px;

				@media (max-width:$large-tablet){
					margin-right: 230px;
				}
			}
		}
	}

	.rect-images {
    width: 100%;
    max-width: calc(100% - 200px);
    margin: auto;
		.stat-wrap{
			display: flex;
			width: 100%;

			.img1,.img2{
				width: 50%;
				display: block;
				img{
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}

		@media (max-width:$large-tablet){
			width: calc(100% - 25px);
			max-width: calc(100% - 50px);
		}
		@media (max-width: $mobile){
			.stat-wrap{
				flex-direction: column;
				.img1,.img2{
					width: 100%;
					display: flex;
					padding: 20px 0 0;
				}
			}
		}
	}
}

@keyframes rotate {
	to{
		transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
	}
}

