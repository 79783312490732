@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';


.ChickenTreat-wrapper{

  .component-case-copy-wrapper {
    margin-top: 60px;
  }

	.component-header-inner-case{
		padding-bottom: 40px;
	}
  
  .component-header-inner-case .sub .foot-image {
    left:-50px;
		max-width:250px;
		bottom:-200px;
		@media(max-width:$large-tablet){
			bottom:-230px;
			max-width:200px !important;
		}
  }

	@media (max-width:$mobile){
		.component-header-inner-case .sub{
			padding-right:0;
		}
		.component-header-inner-case .sub .foot-image{
			max-width: 100%;
			left: 0;
			margin-top: 40px;
			margin-bottom:  20px;
		}
		.component-case-images-gifs-wrapper img{
			max-width: 100%;
		}
	}

	.component-case-copy-wrapper .sub.sub-one:first-child{
		display: none;
	}

  .component-video-wrap video {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .component-case-images-gifs-wrapper {
    width:80%;
    margin:auto;
    img {
      width: 40% !important;
      padding: 20px;
    }

		@media (max-width:$mobile){
			img{
				width: 100% !important;
			}
		}

  }

	.text-block {
		flex-wrap: wrap;
		max-width: 100%;
		position:relative;

		& .stat-wrap:nth-child(1){
			margin-left: 100px;
			margin-right: auto;
			width: 55%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain !important;
		}
		& .stat-wrap:nth-child(3){
			margin-right: 100px;
			margin-left: auto;
			margin-top: 150px;
			width: 55%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain !important;
		}
		& .stat-wrap:nth-child(2){
			width: 45%;
			max-width: 700px;
			min-height: 500px;
			position: absolute;
			right: 0;
			top: 0;
			background-repeat: no-repeat;
			background-position: center;
		}
		& .stat-wrap:nth-child(4){
			width: 38%;
			max-width: 660px;
			min-height: 570px;
			position: absolute;
			left: 0;
			bottom: -60px;
			background-repeat: no-repeat;
			background-position: center;
		}

		@media (max-width:$large-tablet){
			.stat-wrap:nth-child(2),
			.stat-wrap:nth-child(4){
				position: unset;
				padding: 0;
			}
			.stat-wrap:nth-child(2){
				margin-right: -40px;
				margin-left: auto;
			}

			.stat-wrap:nth-child(1),
			.stat-wrap:nth-child(3){
				margin: auto 40px;
			}
		}
		@media (max-width:$mobile){
			.stat-wrap:nth-child(2),
			.stat-wrap:nth-child(4){
				min-height: 300px;
			}
			.stat-wrap:nth-child(4){
				margin-left: -40px;
			}
		}
	}

  .parallax-one {
		text-align:right;
		padding-right:0;
		max-height:50vh;
		position: relative;
		
		img{
			top: -80px;
			right: -160px;
			@media(max-width:$large-tablet){
				top:0 !important;
				left:0 !important;
			}
		}

		@media (max-width:$large-tablet){
			img{
				margin-right: -25px;
			}
		}
		@media (max-width:$mobile){
			padding-right: 0;
		}
	}

	.big-box{
		.lazyload-wrapper {
			display: flex;
		}
	}

}

