@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';


.cicerellos-wrapper {

	.special-wrapper {
		position: relative;
		width: 100%;
		video.special_video_wrapper {
			position: absolute;
			bottom: 341px;
			left: -220px;
			max-width: 800px;
			transform: rotate(92deg) scaleY(-1);
			@media (max-width:$large-tablet){
				bottom: 200px;
				left: -320px;
				max-width: 800px;
			}
			@media (max-width:$tablet){
				max-width: 400px;
				bottom: 0;
				left: 0;
				transform: rotate(0deg) scaleY(-1);
			}
			@media (max-width:$tablet){
				max-width: 200px;
			}
		}
	}
	.component-case-copy-wrapper{
		z-index: 99;
		@media (max-width:$large-tablet){
			margin-top:100px;
		}
		@media (max-width:$mobile){
			margin-top:0;
		}
	}

	.parallax-one {
		text-align:right;
		padding-right:100px;
		position: relative;
		max-height:50vh;
		.header-video{
			position: relative;
			margin: 0;
			.video-bg{
				position: relative;				
			}
			.inner-video{
				position: absolute;

			}
		}
		.header-video:nth-child(1){
			top: -80px;
			right: -50px;
			.video-bg{
				height:100%;	
				width: 100%;			
			}
			.inner-video{
				width: 100%;
				left: 0;
				top: 0;
				height: 100%;
				position: absolute;
				video {
					height: 100%;
					width: 88%;
					top: -11%;
					left: 6%;
					position: absolute;
				}
			}
		}
		.header-video:nth-child(2){
			right: 0;
			transform: translateY(-120%);
			width:24%;
			.video-bg{
				height:100%;	
				width: 100%;			
			}
			.inner-video{
				width: 100%;
				left: 0;
				top: 0;
				height: 100%;
				position: absolute;
				video {
					height: 100%;
					width: 88%;
					top: 1%;
					left: 6%;
					position: absolute;
					transform: scaleY(1.15);
				}
			}
		}

		@media(max-width:$large-tablet){
			padding-right:0;
			.header-video:nth-child(1){
				top: 0px;
				right: -20px;
				width: 80%;
				margin: auto;
			}
			.header-video:nth-child(2){
				top: 0px;
				right: -10px;
				width: 20%;
			}
		}
		@media(max-width:$tablet){
			.header-video:nth-child(1){
				width:100%;
			}
		}
		@media(max-width:$mobile){
		padding-right:0;
		max-height:initial;
		}
	}

	video.inner-video.foot-image.sux {
		bottom: 0px;
    left: -85px;
    max-width: 60vw;
		@media(max-width:$mobile){
			bottom: 55px;
			left: -85px;
			max-width: 100%;	
		}
	}

	img.foot-image.sux {
		opacity:0;
		animation:tentacleIn .6s 1;
		animation-fill-mode:forwards;
		transform:rotate(10deg);
		left:-120px;
		position:relative;
		animation-delay:1s;
	}
	@keyframes tentacleIn {
		0%{
			opacity:0;
			transform:rotate(10deg);
		}
		100%{
			opacity:1;
			transform:rotate(0);
		}
	}
}
