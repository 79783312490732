@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.lever-property-wrapper {

  .component-case-copy-wrapper{
    margin-top:100px;
  }
  @media (max-width:$large-tablet){
    .component-header-inner-case{
      .header-image {
        right:-50px;
      }
    } 
  }
}