@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';


.RevoFitnessGymSimple-wrapper{

  .component-case-copy-wrapper {
    margin-top: 60px;
  }
  
  .component-header-inner-case .sub .foot-image {
    left:0;
    max-width:200px;
  }

  .component-video-wrap video {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .component-case-images-gifs-wrapper {
    width:70%;
    margin:auto;
    img {
      width: 33%;
      padding: 20px;
      // margin:15px;

    }
  }

  .parallax-one {
		text-align:right;
		padding-right:100px;
		position: relative;

		.header-video{
			position: relative;
			margin: 0;
			.video-bg{
				position: relative;				
			}
			.inner-video{
				position: absolute;
			}
		}
		.header-video:nth-child(1){
			top: 0px;
			right: -50px;
			.video-bg{
        height: 100%;
        width: 100%;
			}
			.inner-video{
				width: 100%;
				left: 0;
				top: 0;
				height: 100%;
				position: absolute;
				video {
					height: 100%;
					width: 88%;
					top: -11%;
					left: 6%;
					transform: scaleY(1.03);
					position: absolute;
					@media(max-width:$mobile){
						height: 100%;
					    width: 92%;
					    top: -11%;
					    left: 7%;
					}
				}
			}
		}
		.header-video:nth-child(2){
			top:-70px;
			.video-bg{
				height: 300px;
				width: 240px;
			}
			.inner-video{
				height: 190px;
				left: 66px;
				top: 62px;
				transform: scaleY(1.15);
		
			}
		}

		@media(max-width:$large-tablet){
			padding-right:0;
			.header-video:nth-child(1){
				top: 0px;
				right: 0px;
				width: 80%;
				margin: auto;
			}
			.header-video:nth-child(2){
				right: 50%;
				top:-100px;
				transform: translateX(43%);
				width: 50%;
			}
		}
		@media(max-width:$tablet){
			.header-video:nth-child(1){
				width:100%;
			}
		}
		@media(max-width:$mobile){
			padding-right:0;
			max-height:initial;
			margin-top: 90px;
			margin-bottom: 50px;

			.header-video:nth-child(1) .inner-video video{
				width: 91%;
				top: -11%;
				left: 5%;
			}
			.header-video:nth-child(2){
				right: 55%;
				top: -137px;
				.video-bg{
					height: 190px;
					width: 100px;
				}
				.inner-video {
					height: 120px;
					left: 16px;
					top: 38px;
				}
			}
		}
	}

	.revo-bg-imgs{
		position: relative;
		img.special-img1{
			position: absolute;
			left: 19%;
			top: 22%;
			max-width: 450px;
		}
		img.special-img2{
			position: absolute;
			max-width: 300px;
			top: 2%;
			right: 14%;
	
		}
	}
}

