@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';


.CTF-wrapper {
  .component-header-inner-case .sub .foot-image {
    max-width:200px;
    bottom:-250px;
    @media(max-width:$mobile){
      max-width:200px !important;
      left:-25px !important;
      bottom:-300px;
    }
  }
  .component-header-inner-case .header-image {
    max-width:80%;
    right:-100px;
  }
  .special-wrapper {
    position: relative;
    width: 100%;
    img.special-wrapper {
      left: -120px;
      width: 100%;
      max-width: 350px;
      top: 43%;
      transform: scale(1.5);
      position: absolute;
    }
    .component-case-images-gifs-wrapper {
      flex-wrap:wrap;
      width:70%;
      margin:auto;
      @media(max-width:$mobile){
        width:100% !important;
      }
      img {
        margin:15px;
        min-width: 300px;
        min-height: 300px;
        @media(max-width:$mobile){
          min-width:auto;
          min-height:auto;
          max-width:80%;
        }
      }
      img.special {
        min-height: 450px;
        object-fit: cover;
        min-width: 330px;
        @media(max-width:$large_tablet){
          width:80%;
          min-width:auto;
          min-width:100%;
          min-height:250px;
        }
      }
      img:nth-child(1){
        box-sizing:border-box;
        padding:40px;
        order: 1;
        @media(max-width:$large_tablet){
          padding:40px;
        }
        @media(max-width:$mobile){
          padding:0;
        }
      }
      img:nth-child(2){
        order:2;
      }
      img:nth-child(3){
        order:3;
        @media(max-width:$large_tablet){
          order:4;
        }
  
      }
      img:nth-child(4){
        box-sizing:border-box;
        padding:100px;
        order:4;
        @media(max-width:$large_tablet){
          order:3;
          padding:50px;
        }
        @media(max-width:$mobile){
          padding:50px;
        }
      }
    }
  }

  .logo-section{
    position: relative;
    width: 100%;
    img.special-wrap{
      box-shadow: none;
      right: 40px;
      width: 100%;
      max-width: 250px;
      width: 20%;
      top: 40%;
      transform: rotate(180deg) scale(2.1);
      position: absolute;
      @media(max-width:$large-tablet){
        top:74%;
      }
      // @media(max-width:$tablet){
      //   right: -100px;
      //   width: 100%;
      //   max-width: 400px;
      //   top: 40%;
      //   transform: rotate(90deg) scale(1.5) scaleX(1.4);
      //   opacity: .5;
      // }
      @media(max-width:$mobile){
        display: none;
      }
    }

    .component-case-images-gifs-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      img{
        max-height: 400px;
        margin-right: 40px;
        max-width: 300px;
        width: 20%;

        @media(max-width:$large_tablet){
          width: 50%;
        }
        @media(max-width:$mobile){
          justify-content: center;
          margin-top: 80px;
          max-width:100px;
          margin-right:0;
        }

      }
      @media(max-width:$mobile){
        justify-content: center;
        margin-top: 80px;
      }
    }
  }

}