@import '../../../../Scss/variables.scss';
@import '../../../../Scss/mixins.scss';

.case-nav-wrapper a span {
	line-height:100%;

	
}
.case-nav-wrapper a span{
	@media(max-width:$mobile){
		font-size:14px !important;
	}
}
.case-nav-wrapper a p {
	font-family:$sub-heading-font;
	@media(max-width:$mobile){
		display:none;
	}
}
@media(max-width:$mobile){
	width:calc(100% - 50px);

}