@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.busselton-swim-wrapper {
	@media(max-width:$mobile){
		overflow-x:hidden;
	}
	.component-header-inner-case .sub .foot-image {
		@media(max-width:$mobile){
			max-width:200px !important;
		}
	}
	.special-wrapper {
		position: relative;
		width: 100%;
		img.special-wrapper {
			max-width: 200px;
			right: 34px;
			width: 100%;
			transform: scale(1.5);
			bottom:-30px;
			position: absolute;
			opacity: .5;
		}
	}

	.component-case-copy-wrapper{
		@media (max-width:$tablet){
			margin-top:160px;
		}
	}

	.parallax-one {
		text-align:right;
		padding-right:100px;
		margin-top:200px;
		margin-bottom:-300px;
		@media(max-width:$mobile){
			padding-right:0;
			padding-bottom:50px;
			margin-top:0px;
			margin-bottom:0;
		}
	}
	img.special.header-image{
		position:relative;

		border-radius:25px;
		width:auto !important;
		height:33vh;
		-webkit-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		@media(max-width:$mobile){
			top:0 !important;
			margin-top:50px;
			width:100%;
			max-width:100%;
			
		}
	}
	img.special.header-image:hover {
		position:relative;
		top:auto;
		
	}
	img.special.header-image:nth-child(1){
		top:-80px;

	}
	
	img.special.header-image:nth-child(2){
		margin-right:150px;
		top:-150px;
	}
	img.special.header-image:nth-child(3){
		top:-300px;
	}
	.component-case-stats-wrapper .has_image.stat-wrap img.image-left {
		left:auto;
		right:-50px;
	}
	.component-case-stats-wrapper {
		@media(max-width:$mobile){
			max-width:100%;
		}
		.stat-wrap {
			position:relative;
			
			h4 {
				width:calc(100% - 150px);
				@include font-size(1.5);
				line-height:140%;
				margin:auto;
				margin-bottom:50px;
				@media(max-width:$mobile){
					width:100%;
					margin:0px;
					margin-bottom:25px;
				}
			}
			p {
				@include font-size(1);
			}
			 img.image-left {
			 	width:450px;
			 	z-index:4;
			 }
		}
		.stat-wrap:nth-child(1){
			&::after {
				content:'';
				width:50px;
				position:absolute;
				height:100%;
				background:rgb(10, 78, 132);
				left:-50px;

			}
		}
		.stat-wrap:nth-child(2){
			&::after {
				content:'';
				width:50px;
				position:absolute;
				height:100%;
				background:rgb(10, 78, 132);
				right:-50px;

			}
		}
	}

	.component-case-images-gifs-wrapper {
		flex-wrap:wrap;
		width:70%;
		margin:auto;
		.img{
			margin:15px;
			width: calc(50% - 30px);
		}
		img:nth-child(1){
			box-sizing:border-box;
			padding:90px;
			order:1;
			@media(max-width:$large_tablet){
				padding:50px;;
			}
			@media(max-width:$mobile){
				padding:0;
			}
		}
		img:nth-child(2){order:2;}
		img:nth-child(3){
			order:3;
			@media(max-width:$mobile){
				order:4;
			}
		}
		img:nth-child(4){
			box-sizing:border-box;
			padding:150px;
			order:4;
			@media(max-width:$large_tablet){
				padding:70px;
			}
			@media(max-width:$mobile){
				padding:50px;
				order:3;
			}
		}
	}

}