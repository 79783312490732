@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.ron-farris-wrapper {
	.square-block-images.component-case-images-wrapper {
		
	    max-width: 740px;
	    padding-top: 50px;
	    left: 29px;
	    position: relative;
	    @media(max-width:$large_tablet){
	    	left:0;
	    	flex-wrap:wrap;
	    }

	}
	.sp-image-one {
		padding-right:25px;
		border-right:2px solid rgba(0,0,0,0.2);
		margin-right:0px;
		box-sizing:border-box;
		position:relative;
		@media(max-width:$large_tablet){
			border-right:none;
		}
		&::after {
			content:'';
			width:2px;
			background:rgba(0,0,0,0.2);
			height:15px;
			position:absolute;
			right:-2px;
			bottom:-15px;
			@media(max-width:$large_tablet){
				display:none;
			}
		}
	}
	.sp-image-one,
	.sp-image-two{
		min-height:100px;
		padding-bottom:50px;
		@media(max-width:$large_tablet){
			min-height:50px;
			margin-bottom:50px;
			background-size:300px !important;
			background-position:center !important;
		}
	}
}