@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.revo-fitness-wrapper{

	img.special-image-s {
		-webkit-box-shadow: 0px 0px 83px -45px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 83px -45px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 83px -45px rgba(0,0,0,0.75);
	}
	.revo-fitness-wrapper .component-header-inner-case .sub .foot-image {
		@media(max-width:$mobile){
			max-width:200px !important;
		}
	}
	.vid-img-wrap img {
		@media(max-width:$mobile){
			width:100%;
		}
	}
	.vid-img-wrap {
		display: flex;
		// max-width: Calc(100% - 50px);
		width: 80%;
		margin: 100px auto 0;
		img{
			border-radius: 35px;
		}
		@media(max-width:$large-tablet){
			width:90%;
			flex-wrap:wrap;
			img{
				margin:auto;
			}
			video.special-video{
				margin:0;
				margin-bottom:80px;
			}
		}
	}
	
	.special-image {
		-webkit-box-shadow: 0px 0px 83px -45px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 83px -45px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 83px -45px rgba(0,0,0,0.75);
		border-radius:25px;
		padding:25px;
		margin:25px;
		@media(max-width:$mobile){
			width: 100% !important;
		}
	}
	.special-video {
		-webkit-box-shadow: 0px 0px 83px -45px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 83px -45px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 83px -45px rgba(0,0,0,0.75);
		border-radius:25px;
		margin:50px;
		height:450px;
		background-color:#000;
	
		@media(max-width:$mobile){
			width: 100% !important;
		}
	}
	.special-image-three {
		margin:0 50px;
		@media(max-width:$mobile){
			width:100% !important;
			margin:50px 0;
		}
	}
	
	.gif-wrapper {
		margin-top:100px;
		margin-bottom:100px;
	}
}