@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';


.ScotchCollege-wrapper{

  .component-case-copy-wrapper {
    margin-top: 0;
  }

  .component-header-inner-case .sub-one-sub.one{
    max-height: unset;
  }
  
  .component-header-inner-case .sub .foot-image {
    left:0;
  }

  .component-video-wrap video {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .component-case-images-gifs-wrapper {
    width:70%;
    margin:auto;
    img {
      width: 33%;
      padding: 20px;
      // margin:15px;

    }
  }
}

