@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';


.ComponentReviewSlider {
	position: relative;
    padding-bottom: 100px;
    background: #eee;
    padding-top: 50px;
    overflow:hidden;
    width:100%;
    margin:auto;
    margin-top:50px;
    color:$ok_black;
    .wrapper .company-title {
			// margin-top:5px;
			font-family:$sub-heading-font;
			font-weight:400;
			@include font-size(0.9);
			margin-top:0;
		}
    .star-rating {
    	display:flex;
    }
    .star {
    	width:25px;
    }
    .react-reveal {
    	top:0;
    }
    .slick-arrow {
    	position: absolute;
	    bottom: -51px;
	    top: auto;
	    z-index: 5;
	    cursor: pointer;

	    @media(max-width:$large_tablet){
	    	bottom:-37px;
	    	img {
	    		width:30px !important;
	    	}
	    }
	    @media(max-width:$mobile){
	    	bottom:0;
	    	display:none !important;
	    	img {
	    		width:50px !important;
	    	}
	    }
    }
    .slick-arrow.next {
    	left: auto;
    	right: 0;
    	@media(max-width:$large_tablet){
	    	left:0px;
	    }
	    @media(max-width:$mobile){
	    	text-align:right;
	    }
    }
    .slick-arrow.prev {
	    left: 0;
	    right: auto;
	}
	.wrapper {
		width: calc(100% - 200px);
	    margin: auto;
	    margin-bottom: 50px;
	    text-align: left;
		text-align:left;
		@media(max-width:$mobile){
			width:calc(100% - 50px);
		}
		.quote-wrapper {
		    display: flex;
		    flex-direction: row-reverse;
		    // position: relative;
			  //   bottom: 50px;
			  //   right: 0;
				position:absolute;
				bottom:40px;
				right:0;
			    filter: invert(0.2);
			    opacity: .7;
					@media(max-width:$tablet){
						bottom:20px;
					}
		}



		h3 {
			@include font-size(3);
			letter-spacing:-1px;
			text-align:left;
			width:50%;
			line-height:90%;
			color:$ok_black;
			@media(max-width:$large_tablet){
				@include font-size(4);
				width:100%;
				position:relative;
			}
			@media(max-width:$mobile){
				@include font-size(3.3);
				width:100%;
				position:relative;
			}
		}
		h4 {
			margin-bottom:5px;
		}
		p {
			font-family: $sub-font;
		}
		.slick-dots {
			text-align:center;
			bottom:-50px;
			@media(max-width:$mobile){
				bottom:-30px;
			}
			button {
				&::before {
					color:$ok_black;
				}
			}
			.slick-active {
				button {
					&::before {
						color:$ok_black;
						opacity:1;
					}
				}
			}
		}
		.slick-slide {

			div > span {
		    width: calc(100% - 65px);
		    display: block;
		    color:$ok_black;
		    position:relative;
				display: flex;
				flex-wrap:wrap;
				min-height: 300px;
		    @media(max-width:$large_tablet){
		    	width:100%;
		    }
			}
		}
	}
	
}