@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.HBF-wrapper {
	.component-case-copy-wrapper{
		margin-top:100px;
	}
	img.bouncing-teeth {
		width: 30%;
	    position: absolute;
	    bottom: 0;
	    bottom: -100px;
	    left: 0;

	 }
	 .component-case-images-gifs-wrapper {
	 	position:relative;
	 }
	 .gif-phone-wrapper {
	 	max-width:1300px;
	 	margin:auto;
	 }
	 .phone-gif {
	 	border-radius:33px;
	 	overflow:hidden;
	 	margin:25px;
	 	box-shadow:0 0 77px -30px rgba(0, 0, 0, 0.75);
	 }
}