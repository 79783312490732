@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';

.home-page {

	.component-header-inner.home{
		.header-content{
			.sub-one h1 span{
				letter-spacing: -2px;
			}
		}
	}
	.component-cta-footer .contact-links .button {
		background:#00afb5;
	}
	.component-cta-footer .footer-wrapper .left-content .social-links a{
		letter-spacing: .03rem;
    font-family: DM Sans,sans-serif;
    font-size: 1rem;
    line-height: 23.5px;
    text-decoration:underline;
	}
	.component-cta-footer .footer-wrapper .left-content ul li a {
		letter-spacing: .03rem;
	    font-family: DM Sans,sans-serif;
	    font-size: .75rem;
	    line-height: 16px;
	    text-decoration: none;
	}
	
	.component-cta-footer div h3 {
		font-family:"Helvetica Neue LT W02_93 BlkEx";
		letter-spacing:-.1rem !important;
      font-weight:700;
      line-height:100% !important;
      @include font-size(3.25);
	}
	h2 {
		margin-bottom:50px;
		font-family:'DM Sans',sans-serif;
		font-weight:700;
		@include font-size(1.8);
		max-width:415px;
		line-height:100%;
	}
	ul.signature-client-list {
		    display: flex;
		    flex-wrap: wrap;
		    padding-left: 0;
		    margin-left: 15px;
		    width: calc(100% - 15px);
		    text-decoration: underline;
		    &.internal-list {
		    	li {
		    		width:100%;
		    	}
		    }
		    li {
	    	    width: calc(33.33% - 15px);
			    margin-right: 15px;
			    margin-bottom: 15px;
			    @media(max-width:$tablet) {
			    	width:calc(50% - 15px);
			    }
			    @media(max-width:$mobile) {
			    	width:100%;
			    	margin-right:0;
			    }
		    }
	}
	.content-wrapper {
		text-align: left;
	    box-sizing: border-box;
	    padding: 0 100px;
	    @media(max-width:$mobile){
	    	padding:0 25px;
	    }
		text-align:left;
		h2 {
			@include font-size(2.5);
			text-transform:capitalize;
		}
		p.terms-disclaimer{
			font-size: .85rem;
			width: 70%;
			line-height: 153%;
			letter-spacing: 1px;
			font-family: $sub-heading-font;
			@media(max-width:$mobile){
				width: 100%;
			}
		}

	}
	.component-header-home {
		display:flex;

		div:nth-child(1){
			width:80%;
		}
		div:nth-child(2){
			width:70% !important;
			position:relative;
			top:81px;
			animation:fadeIn 1s 1;
			animation-fill-mode:forwards;
			opacity:0;
		}
		@keyframes fadeIn {
			0%{
				opacity:0;
			}
			100%{
				opacity:1;
			}
		}
	}

	.left-button-link-wrapper .tech-slide-wrap .tech-slideshow span {
		color:$ok_black;
	}
	.component-internal-big-link {
		margin-top: 50px;
	}
	.component-internal-big-link .tech-slide-wrap{
		display: none;
	}
	.component-header-home {
		

	}
	img.crumb {
	    position: absolute;
	    bottom: -250px;
	    right: 0;
	    max-width: 150px;
	    z-index: 1;
	}

	.component-services-box .sub-one h2{
		margin-bottom:50px;
	}
}