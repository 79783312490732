@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.laptop-wrapper {
    position: relative;
    margin-top: 50px;
    span {
    	position:absolute;
    	width:100%;
    	height:50%;
    	background:#d20f38;
    	z-index:-1;
    	top:10%;
    }

}
.twin-fin-wrapper {
    .component-case-copy-wrapper{
        margin-top:100px;
    }
    .component-case-images-wrapper .stat-wrap {
        @media(max-width:$mobile){
            background-size:contain !important;
            min-height:250px;
            &:nth-child(2),
             &:nth-child(3)
            {
                background-size:80% !important;
            }
        }
    }
   .footer-images.component-case-images-wrapper .stat-wrap {
        @media(max-width:$mobile){
            background-size:cover !important;
        }
    }
    .component-case-images-gifs-wrapper {
        img.special {
         -webkit-box-shadow: 0px 0px 77px -30px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 77px -30px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 77px -30px rgba(0,0,0,0.75);
        }
    }
    
}
