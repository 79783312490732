@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.DDC-wrapper {
	@media(max-width:$mobile){
		overflow-x:hidden;
	}
    .special-wrapper {
        position: relative;
        width: 100%;
        img.special-wrapper {
			max-width: 200px;
			right: -70px;
			width: 100%;
			transform: scale(2.5);
			top: 30%;
			position: absolute;
			opacity: .5;
        }
    }
    .special-wrapper2 {
        position: relative;
        width: 100%;
        img.special-wrapper2 {
			max-width: 200px;
			left: -90px;
			width: 100%;
			transform: scale(2.5);
			top: 30%;
			position: absolute;
			opacity: .5;
        }
    }

	.parallax-one {
		text-align:right;
		padding-right:100px;
		max-height:50vh;
		@media(max-width:$mobile){
			padding-right:0;
			max-height:initial;
			padding-bottom:50px;
		}
	}
	img.special.header-image{
		position:relative;

		border-radius:25px;
		max-width:65%;
		width:auto;
		height:33vh;
		-webkit-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		@media(max-width:$mobile){
			top:0 !important;
			margin-top:50px;
			width:100%;
			max-width:100%;
			
		}
	}
	img.special.header-image:hover {
		position:relative;
		top:auto;
		
	}
	img.special.header-image:nth-child(1){
		top:-80px;

	}
	
	img.special.header-image:nth-child(2){
		margin-right:150px;
		top:-150px;
	}
	img.special.header-image:nth-child(3){
		top:-300px;
	}
	.component-case-stats-wrapper .has_image.stat-wrap img.image-left {
		left:auto;
		right:-50px;
	}
	.component-case-stats-wrapper {
		@media(max-width:$mobile){
			max-width:100%;
		}
		.stat-wrap {
			position:relative;
			
			h4 {
				width:calc(100% - 150px);
				@include font-size(1.5);
				line-height:140%;
				margin:auto;
				margin-bottom:50px;
				@media(max-width:$mobile){
					width:100%;
					margin:0px;
					margin-bottom:25px;
				}
			}
			p {
				@include font-size(1);
			}
			 img.image-left {
			 	width:450px;
			 	z-index:4;
			 }
		}
		.stat-wrap:nth-child(1){
			&::after {
				content:'';
				width:50px;
				position:absolute;
				height:100%;
				background:rgb(10, 78, 132);
				left:-50px;

			}
		}
		.stat-wrap:nth-child(2){
			&::after {
				content:'';
				width:50px;
				position:absolute;
				height:100%;
				background:rgb(10, 78, 132);
				right:-50px;

			}
		}
	}

    .component-case-images-gifs-wrapper {
        flex-wrap:wrap;
        max-width: calc(100% - 400px);
		
        .img,.img-svg {
			position: relative;
            max-width: 300px;
            width: 100%;
            height: 300px;
            margin: 10px 0;
        }
		
        .img {
			object-fit: cover;
        }
		
        .img-svg {
			box-sizing: border-box;
            padding: 0px 30px;
        }
		
		@media screen and (min-width:1600px){
			max-width:1199px;
	
			.img {
				margin: 10px 20px;
			}
		}
		@media screen and (max-width:$small_desktop){
			max-width: calc(100% - 200px);
		}
		@media screen and (max-width:1024px){
			.img {
				max-width:200px;
				height:200px;
			}
		}

		@media screen and (max-width:$tablet){
			.img {
				max-width:600px;
				height: auto;
				margin-bottom:60px;
			}
			.img-svg {
				max-width:300px;
				height: auto;
				margin-bottom:60px;
			}
		}
		@media screen and (max-width:$mobile) {
			max-width: calc(100% - 50px);
		}


    }

	.infographic {
		img {
			max-width:60%;
		}
		@media screen and (max-width:$mobile){
			margin-top:80px !important;
			img {
				max-width:100%;
			}
		}
	}

	.logo-section {
		div .component-case-images-gifs-wrapper{
			max-width:90%;
			justify-content: space-between;

			.img-logo {
				margin: 0 20px;
				max-height: 120px;
			}
			@media screen and (max-width:$tablet){
				flex-wrap: wrap;
				margin-top:80px !important;

				.img-logo {
					margin: 0 auto 40px;
				}
			}
			@media screen and (max-width:$mobile){
			}
		}
	}

	.component-full-bleed-image {
		@media screen and (max-width:$tablet){
			margin-top:80px !important;
		}
	}

    .img-gifs {
        position: relative;
    }
}