@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';

canvas {
	background-size:contain;
	background-repeat:no-repeat;
	background-position:center;
	animation: bounce 1s 4 alternate;
  -webkit-animation: bounce 1s 4 alternate;
	position:relative;
}

@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-15px);
  }
}