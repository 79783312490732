@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.mkdc-wrapper {
	.component-case-copy-wrapper{
		margin-top:50px;
		@media(max-width:$large-tablet){
			margin-top:120px;
		}
	}
	.parallax-one {
		text-align:right;
		padding-right:100px;
		@media(max-width:$mobile){
			padding-right:0;
			max-height:initial;
			padding-bottom:50px;
		}
	}
	img.foot-image.sux {
	    margin-left: 90%;
	    top:0;
			@media(max-width:$large-tablet){
				margin-left:0;
				margin-top:792px;
			}
	}
	img.special.header-image{
		position:relative;

		border-radius:25px;
		max-width:100%;
		width:100%;
		@media(max-width:$mobile){
			top:0 !important;
			margin-top:50px;
			width:100%;
			max-width:100%;
			
		}
	}
	img.special.header-image:hover {
		position:relative;
		top:auto;
		
	}
	img.special.header-image:nth-child(1){

	}
	
	img.special.header-image:nth-child(2){
		position:absolute;
		bottom:-50px;
		margin-right:50px;
		max-width:50%;
	}


	.component-case-stats-wrapper {
		flex-wrap:wrap;
		@media(max-width:$mobile){
			max-width:100%;
		}
		.stat-wrap {
			position:relative;
			
			h4 {
				width:calc(100% - 150px);
				@include font-size(1.5);
				line-height:140%;
				margin:auto;
				margin-bottom:50px;
				color: #ffffff;
				@media(max-width:$mobile){
					width:100%;
					margin:0px;
					margin-bottom:25px;
					@include font-size(1);
				}
			}
			p {
				color: #ffffff;
				@include font-size(1);
			}
		
		}
		.stat-wrap:nth-child(1){
			width:60%;
			@media(max-width:$tablet){
				width:100%;
			}
			@media(max-width:$mobile){
				width:100%;
			}
		}
		.stat-wrap:nth-child(2){
			width:40%;
			position:relative;
			overflow:hidden;
			img {
				height:100%;
				position:absolute;
				width:auto;
				top:0;
				left:0;
				object-fit:cover;
			}
			@media(max-width:$large-tablet){
				display: block;
			}
			@media(max-width:$tablet){
				display: block;
				width:100%;
				img {
					max-width:100% !important;
				}
			}
			// @media(max-width:$mobile){
			// 	display:block;
			// 	width:100%;
			// 	img {
			// 		max-width:100% !important;
			// 	}
			// }
		}
		.stat-wrap:nth-child(3){
			position:relative;
			top:0;
			min-height:auto;
			height:auto;
			width:100%;
			right:0;

			img.image-left {
				left:auto;
				right:50px;
				top:0;
			}
			@media(max-width:$mobile){
				width:100%;
				display:none;
				img {
					height:100%;
					right:0;
					left:auto;
				}
			}
		}

	}

}