@import '../../../../Scss/variables.scss';
@import '../../../../Scss/mixins.scss';

.component-video-wrap {
	margin-top:150px;
	box-sizing:border-box;
	padding:0 100px;
	@media(max-width:$mobile){
		padding:0 25px;
	}
	video {
		height:100%;
		transition:.3s;
		left:0;
	}
	.lazyload-wrapper {
		position:relative;
		width:100%;
	}
	h3 {
		@include font-size(2.5);
		text-align:left;
		margin-bottom:15px;
		color:$ok_black;
		font-family:$heading_font;
		font-weight:bold;
	}

	// For videos with a desktop mac frame
	.header-video{
		position: relative;
		margin: 0;
		.video-bg{
			position: relative;				
		}
		.inner-video{
			position: absolute;

		}
	}
	.header-video:nth-child(1){
		.video-bg{
			height:100%;	
			width: 100%;			
		}
		.inner-video{
			width: 100%;
			left: 0;
			top: 0;
			height: 100%;
			position: absolute;
			video {
				height: 100%;
				width: 88%;
				top: -11%;
				left: 6%;
				position: absolute;
			}
		}
	}

	@media(max-width:$large-tablet){
		.header-video:nth-child(1){
			top: 0px;
			right: 0px;
			width: 80%;
			margin: auto;
		}
	}
	@media(max-width:$tablet){
		.header-video:nth-child(1){
			width:100%;
		}
	}
}
.component-video-wrap.screen-cast {
	video {
		border-radius: 25px;
        -webkit-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 120px -49px rgba(0,0,0,0.75);
		margin-top:25px;
		border-top-left-radius:0 !important;
		border-top-right-radius:0 !important;

	}
	span.video-hide {
    display: none;
}
span.video-min-show {
	transition:.3s;
}
span.video-min {
	display: block;
    position: fixed;
    top: 0;
    left: 100px;
    margin-top: 100px;
    z-index: 9;
    transition:.4s;
    @media(max-width:$mobile){
    	left:15px;
    }
	video {
	    height:auto;
	    overflow: hidden;
	    transition: .4s;
	    width:350px;
	    left:0;
	}
    .lazyload-wrapper {
    	width:350px;
    	transition:.3s;
    }
}
.lazyload-wrapper {
	transition:.3s;
}
span.video-blow .lazyload-wrapper {
    transform: scale(1.14);
    transition: .5s;
}
	.laptop-display {
	    position: absolute;
	    width: 100%;
	    height: 25px;
	    background: #dedcdc;
	    border-top-left-radius: 15px;
	    border-top-right-radius: 15px;
	    div {
		    width: auto;
		    display: flex;
		    align-items: center;
		    padding-top: 4px;
		    padding-left: 15px;
		    span {
			    width: 10px;
			    height: 10px;
			    background: #333;
			    border-radius: 100%;
			    margin: 4px;
			    transition:.3s;
			    &.cross-off:hover {
			    	background:$ok_red;
			    	transition:.4s;
			    }
			    &.minimise:hover {
			    	background:$ok_orange;
			    	transition:.4s;

			    }
			    &.expand:hover {
			    	background:$ok_green;
			    	transition:.4s;
			    }
			}
			
		
		}
	}


}