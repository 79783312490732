@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';


.FunkyMonkey-wrapper{
  
  .component-case-copy-wrapper {
    margin-top: 80px;
  }

  .component-header-inner-case .sub .foot-image {
    left:0;
  }

  .component-case-images-gifs-wrapper {
    width:70%;
    margin:auto;
    img {
      width: 33%;
      margin: 20px;
      border-radius: 20px;
      box-shadow: 2px 2px 23px 0px rgba(0,0,0,.35);
    }
  }
}

