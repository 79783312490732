@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';

.component-footer {
  position:relative;
  padding-top:50px;
  top:50px;
  position:relative;
  z-index:1;
  margin-left:100px;
  margin-right:100px;
  background:$ok_black;
  padding:50px;
  padding-bottom:0;
  @media(max-width:$mobile){
    margin:0px 25px;
  }
  a {
    transition:.6s;
    &:hover {
      color:$ok_green !important;
      transition:.6s;
    }
  }

  .footer-wrapper {
    display:flex;
    margin:auto;
    margin-bottom:0px;
    padding-top:50px;
    @media(max-width:$mobile){
      margin-left:20px;
      flex-wrap:wrap;
      margin-bottom:20px;
       width:calc(100% - 40px);
    }
    .row h5 {
      @include font-size(1.9);
      margin-bottom: 0;
      margin-top: 0;
      color:$ok_white;
      font-family:$heading-font;
      letter-spacing:0;
    }

    .left-content {
      display: flex;
      text-align: left;
      flex-wrap: wrap;
      width: 100%;
      margin-right: 50px;
      @media(max-width:$mobile){
        width:100%;
      }
      .row {
        width:33.33%;
        @media(max-width:$mobile){
          width:100%;
        }
      }
      .social-links {
        display:flex;
        margin-top:50px;
        @media(max-width:$mobile){
          display:block;
        }
        a {
          margin-right:15px;
          text-decoration:underline;
          @include font-size(1.05);
          @media(max-width:$mobile){
            display:block;
            padding:10px 0 15px 0;
          }
        }
      }

      .row.row-one {
        text-align: left;
      }
      .row.row-four {
         ul {
         
          img {
            width:15px;
            margin-right:15px;
          }
         }
      }
      .row.addresses{
        ul li {
          font-family: $sub-heading-font;
        }
      }

      ul {
        padding-left:0;
        list-style:none;
        padding-right:20px;
        box-sizing:border-box;

        li {
          @include font-size(.8);
          color:$ok_white;
          font-weight:400;
          letter-spacing:0.3px;
          font-family: $sub-font;
          img {
            width:25px;
            margin-right:15px;
          }
          a {
            @include font-size(.8);
            color:$ok_white;
           font-weight:400;
          }
        }
      }
    }
    .right-content {
      @media(max-width:$mobile){
        width:100%;
        text-align:left;
        border-top:1px solid #eee;
        padding-top:25px;
      }
      ul {
        list-style:none;
        padding-left:0;
        text-align:left;
        li {
         @include font-size(.8);
         color:$ok_white;
         font-weight:bold;
          a {
            @include font-size(.8);
            color:$ok_white;
            font-weight:bold;
          }
        }
      }
    }

  }
   .bottom-content {
    padding-bottom:50px;
      ul {
        padding-left: 0;
        list-style: none;
        display: flex;
        width: calc(100% - 100px);
        margin-right: 50px;
        @media(max-width:$mobile){
          margin-left:20px;
          display: block;
          text-align: left;
          border-top: 1px solid $ok_black;
          padding-top: 25px;
        }
        
        li {
          @include font-size(.7);
          color:$ok_white;
          margin-right:15px;
          margin-bottom:5px;
          font-weight:400;
          letter-spacing:0.3px;
          a {
            @include font-size(.7);
            color:$ok_white;
            font-weight:400;
            padding:5px;
            letter-spacing:0.3px;
            @media(max-width:$mobile){
              display:block;
              padding-bottom:0;
              padding-left:0;
            }
          }
        }
      }
    }
}