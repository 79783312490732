@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.case-nav-wrapper {
	width:100%;
	width: calc(100% - 200px);
    margin: auto;
    margin-top: 50px;
    @media(max-width:$mobile){
		width:calc(100% - 50px);

	}
    
    a {
    	color:$ok_black;

    	span {
    		font-weight:bold;
    		font-family:$heading_font;
    		margin-right:15px;
    		@include font-size(1.4);
    		letter-spacing:-0.5px;
    	}
    }

	.sub {
		display:block;
		justify-content:center;
		position:relative;
		min-height:50px;
	}
	.sub.sub-one {
		text-align:left;
		img {
			margin-left:15px;
		}
		a {
			width:100%;
			display:flex;
			align-items:center;
			text-align:left;
		}
	}
	.sub.sub-two {
		text-align:right;
		img {
			margin-right:15px;
		}
		> div {
			position:absolute;
			right:0;
		}
		a {
			width:100%;
			display:flex;
			align-items:center;
			text-align:right;
		}
	}
}