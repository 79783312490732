@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.foodies-market-wrapper {
  .square-block-images {
      flex-wrap: wrap;
      max-width: 950px;
      width: 100%;
      margin: auto;
      padding-top:50px;
      
  }
  .component-case-images-wrapper {
    @media(max-width:$large_tablet){
      .stat-wrap {
        height:250px;
        background-size:250px 250px !important;
        min-height:auto;
      }
     
    }
   }

   .component-case-images-gifs-wrapper {
		flex-wrap:wrap;
		width:70%;
		margin:auto;
		img {
			margin:15px;
		}
		img:nth-child(1){
			box-sizing:border-box;
			padding:120px;
			@media(max-width:$large_tablet){
				padding:50px;;
			}
			@media(max-width:$mobile){
				padding:0;
				margin-bottom:50px;
			}
		}
		img:nth-child(4){
			box-sizing:border-box;
			padding:170px;
			@media(max-width:$large_tablet){
				padding:100px;
			}
			@media(max-width:$mobile){
				padding:50px;
			}
		}
	}

}