@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.perth-brain-center-wrapper {

	.component-case-copy-wrapper{
		margin-top:160px;
		@media (max-width:$large-tablet){
			margin-top:100px;
		}
		@media (max-width:$mobile){
			margin-top:0;
		}
	}
	.component-header-inner-case .sub .foot-image {
		@media(max-width:$mobile){
			max-width:200px !important;
		}
	}

	.parallax-one {
		text-align:right;
		padding-right:100px;
		max-height:50vh;
		position: relative;
		.header-video{
			position: relative;
			margin: 0;
			.video-bg{
				position: relative;				
			}
			.inner-video{
				position: absolute;

			}
		}
		.header-video:nth-child(1){
			top: -20px;
			right: -50px;
			.video-bg{
				height:100%;	
				width: 100%;			
			}
			.inner-video{
				width: 100%;
				left: 0;
				top: 0;
				height: 100%;
				position: absolute;
				video {
					height: 100%;
					width: 88%;
					top: -11%;
					left: 6%;
					position: absolute;
				}
			}
		}
		.header-video:nth-child(2){
			right: 0;
			transform: translateY(-120%);
			width:24%;
			.video-bg{
				height:100%;	
				width: 100%;			
			}
			.inner-video{
				width: 100%;
				left: 0;
				top: 0;
				height: 100%;
				position: absolute;
				video {
					height: 100%;
					width: 88%;
					top: 1%;
					left: 6%;
					position: absolute;
					transform: scaleY(1.15);
				}
			}
		}

		@media(max-width:$large-tablet){
			padding-right:0;
			.header-video:nth-child(1){
				top: 0px;
				right: 0px;
				width: 80%;
				margin: auto;
			}
			.header-video:nth-child(2){
				top: 0px;
				right: -10px;
				width: 20%;
			}
		}
		@media(max-width:$tablet){
			.header-video:nth-child(1){
				width:100%;
			}
		}
		@media(max-width:$mobile){
		padding-right:0;
		max-height:initial;
		}
	}


	.component-case-images-gifs-wrapper {
	    max-width: 100%;
	    margin-left:100px;
	    @media(max-width:$mobile){
	    	margin-left:0;
	    }
	}
}
