@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.the-fast-800-wrapper{
  .component-case-images-gifs-wrapper{
    .side-gif {
      transform: scale(0.85);
    }
    .special {
      margin: 0 20px;
      transform: scale(1.1);
    }

    @media (max-width:600px){
      img {
        padding: 20px 0;
      }
      .side-gif,.special{
        margin: 0;
        transform: scale(1);
      }
    }
  }
}