@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';


.blob-one {
	width: 100%;
    height: 100%;
    opacity: 1;
    position: absolute;
    animation: c 24s ease-in-out infinite;
    top:25px;
    opacity:0;
    z-index:-1;
    right:150px;
    max-width:400px;
    @media(max-width:$mobile){
    	width:100% !important;
    	height:100% !important;
    	max-width:250px !important;
    	max-height:250px !important;
        margin-top:100px !important;
        left:0;
    }

}
.animIn .blob-one {
	opacity:1;
	transition:.9s;
}

@keyframes c {
	0%{
		transform:scale(1) translate(0);
	}
	65%{
		transform:scale(.6,1.3) translate(.5vw,-5vh) rotate(180deg);
	}	
	100%{
		transform:scale(1) translate(0) rotate(0deg);
	}	
}