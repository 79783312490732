@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';


.PLS-wrapper{

  .component-case-copy-wrapper {
    margin-top: 60px;
		padding-bottom: 60px;
		@media (max-width:$mobile){
			.sub.sub-one{
				display:none;
			}
		}
  }

	.component-header-inner-case{
		padding-bottom: 40px;
	}
  
  .component-header-inner-case .sub .foot-image {
    left:-50px;
    max-width:250px;
    bottom:-200px;
    @media(max-width:$large-tablet){
    	max-width:180px;
    }
		@media (max-width:$mobile){
			margin-left: -25px;
		}
  }

	.logo-block{
		.lazyload-wrapper  img{
			max-width: 800px !important;
		}
	}

  .component-video-wrap video {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
	.component-video-wrap{
		.header-video{
			min-height: 800px;
			.video-bg{
				z-index: -10;
			}
			.inner-video{
				.scroll-vid{
					top: 0;
					width: 90%;
					top: -1%;
					left: 5%;
				}
			}
		}
	}

  .component-case-images-gifs-wrapper {
    width:70%;
    margin:auto;
    img {
      width: 33%;
      padding: 20px;
    }
  }

  .parallax-one {
		text-align:right;
		padding-right:0;
		max-height:50vh;
		position: relative;
		.multi-image-wrap{
			position: relative;
			img{
				box-shadow: unset;
				max-width: 60%;
				&:hover{
					transform: unset;
				}
			}
			img:nth-child(1){
				position: absolute;
				left: 50px;
				z-index: 10;
				-webkit-animation: rightIn 2s ease;
				-moz-animation: rightIn 2s ease;
				animation: rightIn 2s ease;				
			}
			img:nth-child(2){
				margin-top: -40px;
				-webkit-animation: rightIn 4s ease;
				-moz-animation: rightIn 4s ease;
				animation: rightIn 4s ease;				
			}
		}


		@media (max-width:$large-tablet){

		}

		@media (max-width:$mobile){
			.multi-image-wrap{
				img:nth-child(1){
					left: -20px;
					max-width: 80%;
				}
				img:nth-child(2){
					margin-top: -20px;
					max-width: 80%;
					margin-right: -30px;
				}
			}
		}
	}

	.big-box{
		.lazyload-wrapper {
			display: flex;
		}
	}


}

@keyframes rightIn {
	0%{
		opacity: 0;
		transform: translateX(-200%);
		-webkit-transform: translateX(-200%);

	}
	60%{
		opacity: 0;
	}
	100%{
		transform: translateX(0);
		-webkit-transform: translateX(0);
	}
}