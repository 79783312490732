@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.waac-wrapper {
	.component-header-inner-case .header-image {
		@media(max-width:$mobile){
			position: relative;
	    bottom: 0;
	    width: 100% !important;
	    top: 0 !important;
	    right: 0 !important;
		}
	}
	.component-full-bleed-image img {
		@media(max-width:$mobile){
			left: 0 !important;
		}
	}
}