@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';


.SourceEnergy-wrapper{

  .special-wrapper {
    position: relative;
    width: 100%;
    img.special-wrapper {
      max-width: 200px;
      right: -50px;
      width: 100%;
      transform: scale(1.5);
      top: 441px;
      position: absolute;
      opacity: .8;
    }
  }

  .component-case-copy-wrapper{
		margin-top: 100px;
    .foot-image{
      position: absolute;
      right: -80px;
      top: 180px;
			max-width:250px;
    }
  }

  .parallax-one {
		text-align:right;
		padding-right:100px;
		position: relative;
		@media(max-width:$mobile){
			margin-top:0 !important;
		}

		.header-video{
			position: relative;
			margin: 0;
			.video-bg{
				position: relative;				
			}
			.inner-video{
				position: absolute;

			}
		}
		.header-video:nth-child(1){
			top: -80px;
			right: -50px;
			.video-bg{
				height:105%;	
				width: 105%;		
				@media(max-width:$mobile){
					width:100%;
					height:100%;
					margin-top:100px;
				}	
			}
			.inner-video{
				width: 100%;
				left: 0;
				top: 0;
				height: 100%;
				position: absolute;
				video {
					height: 100%;
					width: 88%;
					top: -11%;
					left: 6%;
					position: absolute;
				}
			}
		}

		@media(max-width:$large-tablet){
			padding-right:0;
			.header-video:nth-child(1){
				top: 0px;
				right: 0px;
				width: 80%;
				margin: auto;
			}
		}
		@media(max-width:$tablet){
			.header-video:nth-child(1){
				width:100%;
			}
		}
		@media(max-width:$mobile){
			padding-right:0;
			max-height:initial;
			margin-top: 90px;
			margin-bottom: 50px;

		}
	}

}