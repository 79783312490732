@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.cicerellos-scent-of-summer-wrapper {


	.video-with-special{
		position: relative;
		img{
			position: absolute;
			right: 0;
			top: -260px;
			max-width: 300px;

			@media (max-width:$large-tablet){
				top: -150px;
				max-width: 200px;
			}
		}
	}

	.component-video-wrap video{
		border-radius:20px;
	}

	.square-block-images.component-case-images-wrapper {
	    max-width: 900px;
	    margin-top:50px;
	    .stat-wrap {
	    	min-height:250px;
	    	padding:25px;
	    	box-sizing:border-box;
	    }
	}
	.animated-animals {
	    width: 100%;
	    display: flex;
	    align-items: center;
	    max-width: 950px;
	    margin: auto;
	    @media(max-width:$tablet){
	    	display:block;
	    	margin-top:50px;
	    	svg {
	    		max-width:250px;
	    	
	    	}

	    }
	}

}