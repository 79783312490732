@import '../../../../Scss/variables.scss';
@import '../../../../Scss/mixins.scss';

.component-case-stats-wrapper {
    display: flex;
    width: 100%;
    margin-top: 60px;
    max-width: calc(100% - 100px);
    margin: auto;
    margin-top: 100px;
    position:relative;
    @media(max-width:$large_tablet){
        flex-wrap:wrap;
        max-width:calc(100% - 50px);
    }
    .has_image.stat-wrap {
        width: 20%;
        @media(max-width:$large_tablet){
            display:none;
        }
        img.image-right {
            position:absolute;
            right:-50px;
            width:100%;
            max-width:370px;
        }
        img.image-left {
            position:absolute;
            left:-50px;
            width:100%;
            max-width:370px;
        }
    }
    .has_left_image.stat-wrap {
        width: 20%;
        @media(max-width:$large_tablet){
            display:none;
        }
        img {
            position:absolute;
            left:-50px;
            width:100%;
            max-width:370px;
        }
    }
    .stat-wrap {
    	width:100%;
    	box-sizing:border-box;
    	padding:25px;
    	color:$ok_white;
    	min-height:50vh;
    	display:flex;
    	align-items:center;
    	justify-content:center;

    	h4 {
    		@include font-size(3.5);
    		margin-bottom:25px;
    		margin-top:20px;
            @media(max-width:$mobile){
                @include font-size(4.5);
            }
		}
		p {
			font-family:$heading_font;
			@include font-size(2);
			margin-top:0;
			line-height:110%;
			margin-bottom:0;
            width:90%;
            margin:auto;
            @media(max-width:$mobile){
                @include font-size(2);
                width:100%;
            }

		}

    }
}