@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';

.not-found-wrapper {
    width: 100%;
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top:80px;
    position:relative;
    flex-wrap:wrap;
    .heading {
        padding-left:50px;
        animation:headingsIn .7s 1;
        animation-fill-mode:forwards;
        position:relative;
        opacity:0;
        top:25px;
        max-width:500px;
        @media(max-width:$mobile){
            padding-left:25px;
            width:calc(100% - 50px);
        }
    }
    canvas {
        @media(max-width:$mobile){
            width:50%;
        }
    }
    a {
        color:$ok_blue;
        font-weight:bold;
    }
    @keyframes headingsIn {
        0%{
            top:25px;
            opacity:0;
        }
        100%{
            top:0;
            opacity:1;
        }
    }
    h2{
    	@include font-size(5);
    	color:$ok_black;
        text-align:left;

    }
    h3{
        font-size:3rem;
        color:$ok_black;
        text-align:left;
    }
    p {
    	color:$ok_black;
    	font-family:$heading_font;
        text-align:left;

    }
    .component-cta-footer {
		margin-top:50px;
	}
    
}