@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.iga-wrapper {
	.component-case-images-wrapper .stat-wrap {
		@media(max-width:$mobile){
			min-height:200px;
			&:nth-child(1){
				margin-top:50px;
				background-size:70% !important;
			}
		}

	}
	.footer-images.component-case-images-wrapper .stat-wrap {
		@media(max-width:$mobile){
			&:nth-child(1){
				margin-top:0px;
				background-size:cover !important;
			}
		}
	}
}