.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}
.scroll-active .desktop {
  transition:.5s;
  padding:10px 50px;
}
.loader-wrapper {
  background:#fff;
  height:100vh;
  padding:25px;
  width:100vw;
  display:flex;
  align-items:center;
  color:#2C2B2D;
}
.loading-logo {
  animation:loadingLogoIn .5s 1;
  animation-fill-mode:forwards;
  animation-delay:.5s;
  position:relative;
  left:15px;
  opacity:0;
}
@keyframes loadingLogoIn {
  0%{
    opacity:0;
    left:15px;
  }
  100%{
    opacity:1;
    left:0;
  }
}
.loader-wrapper  div {
  width:100%;
}

.page-in span.anim-inner {
  content:'';
  width:100%;
  height:100%;
  position:fixed;
  bottom:0;
  left:0;
  z-index:9999; 
  animation:pageIn 3s 1;
  animation-delay:0s;
  animation-fill-mode:forwards;
  display:flex;
  align-items:center;
  justify-content: center;

}
.page-in.black span.anim-inner{
    background:#2C2B2D;
}
.page-in .component-header-inner .sub-two,
.page-in-out .component-header-inner .sub-two {
  opacity:0;
  animation:blobIn .6s 1;
  animation-delay:.18s;
  animation-fill-mode:forwards;
}
.loader {
      width: 48px;
      height: 48px;
      border:10px solid #FFF;
      border-radius: 50%;
      position: relative;
      transform:rotate(45deg);
      box-sizing: border-box;
      animation:loaderIn 2s 1;
      animation-fill-mode:forwards;
      opacity:0;

    }
    .loader::before {
      content: "";
      position: absolute;
      box-sizing: border-box;
      inset:-10px;
      border-radius: 50%;
      border:10px solid #FF3D00;
      animation: prixClipFix 2s infinite linear;

    }

    @keyframes prixClipFix {
        0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
        25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
        50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
        75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
        100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
    }
@keyframes loaderIn {
  0%{
    opacity:0;
  }
  25%{
    opacity:1
    0
  }
  50%{
    opacity:1
  }
  75%{
    opacity:1
  }
  100%{
    opacity:0;
  }
}
@keyframes blobIn {
  0%{
    opacity:0;
    top:15px;
  }
  100%{
    opacity:1;
    top:0;
  }
}
.page-in-out span.anim-inner{
  content:'';
  width:100%;
  height:100%;
  position:fixed;
  top:0;
  left:0;
  z-index:9999; 
  animation:pageInOut 3s 1;
  animation-delay:0s;
  animation-fill-mode:forwards;
   background-image:url('https://okmg-web-assets-rc.s3.ap-southeast-2.amazonaws.com/okmg-master.svg');
  background-size:contain;
  background-repeat:no-repeat;
  background-position:center;


}
.page-in-out.green span.anim-inner {
   background:#33b14b;
}
@keyframes pageInOut {
  0% {
    height: 100%;
    top: -100vh;
  }
  25%{
    top:0;
  }
  50%{
    top:0;
  }
  100% {
    height: 100%;
    top: 100vh;
    z-index:-5;
    pointer-events:none;
    display:none;
  }
}
@keyframes pageIn {
 0% {
    height: 100%;
    bottom: -100vh;
  }
  25%{
    bottom:0;
  }
  50%{
    bottom:0;
  }
  100% {
    height: 100%;
    bottom: 100vh;
    z-index:-5;
    pointer-events:none;
    display:none;
  }
}

.desktop {
  transition:.5s;
  padding:25px 50px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.react-router-wrapper {
  position:relative;
  left:0;


  &.animIn {

    opacity:1;
    transition:.3s;
    .nav-wrap {
      position:relative;
      margin-left:15px;
      transition:.8s;
    }

 }

  &.animOut {
    opacity:1;


  
   }


}

@media(max-width:600px){
  .scroll-active .desktop {
    transition:.5s;
    padding:20px 20px;
  }
}

