@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';

.blob-two {
	width: 50vw;
    height: 70vh;
    opacity: 1;
    position: absolute;
    left: 0;
    animation: d 22s ease-in-out infinite;
    left: -50px;
    top: 100px;
    opacity:0;
    max-width:550px;
      @media(max-width:$mobile){
    	width:100% !important;
    	height:100% !important;
    	max-width:250px !important;
    	max-height:250px !important;
    	left:0 !important;
    }
}
.animIn .blob-two {
	opacity:1;
	transition:.9s;
}

@keyframes d {
	0%{
		transform:scale(1) translate(0);
	}
	65%{
		transform:scale(.7,1.4) translate(-2vw,9vh) rotate(120deg);
	}	
	100%{
		transform:scale(1) translate(0) rotate(0deg);
	}	
}