@import '../../../../Scss/variables.scss';
@import '../../../../Scss/mixins.scss';

.toggle-no {
    display:none;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 34px;
  position:absolute;
  cursor:pointer;
  margin-left:25px;
  margin-top:50px;
  @media(max-width:$mobile){
    top: -50px;
    margin-top:0;
  }
}
span.toggle-instruct {
    position: relative;
    top: -45px;
    color: $ok_white;
    background: $ok_black;
    padding: 10px 15px;
    font-weight: bold;
    font-size: 12px;
    animation:toggleThis 1.2s infinite;
    &::after {
        content: '';
        width: 15px;
        height: 15px;
        position: absolute;
        bottom: -7px;
        background: $ok_black;
        left: 0;
        right: 0;
        margin: auto;
        transform: rotate(45deg);
    }

}

img {
    position:relative;
}
span.slider.round span {
    position: relative;
    top: 8px;
    font-weight:bold;
    color:#fff;
}

/* Hide default HTML checkbox */
.switch input {
   opacity: 0;
    width: 100%;
    height: 100%;
    top: -23px;
    position: relative;
    z-index: 3;
    cursor:pointer;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #88c3b9;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: rgba(255,255,255,0.5);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #c8b6d9;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(165px);
  -ms-transform: translateX(165px);
  transform: translateX(165px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

