@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.stirling-capital-wrapper {
	.component-header-inner-case .sub .foot-image {
		@media(max-width:$mobile){
			left:-25px !important;
			max-width:200px !important;
		}
	}
}