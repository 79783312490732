@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';

.component-full-width-link {
    position: relative;
    img.crumb {
	    position: absolute;
	    right: 0;
	    max-width: 94px;
	    bottom: 0;
	    @media(max-width:$mobile){
	    	display:none;
	    }
    }
}

.mark,
.mark-alt {
	position: relative;
    width: 100vw;
    max-width: 100%;
    height: 200px;
    overflow-x: hidden;
  .sub-mark {
  	  position: absolute;
	  white-space: nowrap;
	  will-change: transform;
	  animation: marquee 30s linear infinite;
	  font-family:$heading_font;
	  margin:0;
	  top:22px;
  }
  .sub-mark-alt {
  		position: absolute;
	  white-space: nowrap;
	  will-change: transform;
	  animation: marquee_alt 30s linear infinite;
	  font-family:$heading_font;
	  margin:0;
	  top:22px;
  }
}
@keyframes marquee {
  from { transform: translateX(0); }
  to { transform: translateX(-50%); }
}

@keyframes marquee_alt {
  from { transform: translateX(-50%); }
  to { transform: translateX(0%); }
}

/*//////////
LEFT BUTTON CONTENT
/////////*/
.left-button-link-wrapper {
    width: 100%;
    min-height: 150px;
    display:flex;
    position:relative;
    .sub-one {
    	width:calc(100% - 200px);
    	margin-left:100px;
    	background:red;
    	@media(max-width:$mobile){
    		margin-left:25px;
    		width:100%;
    		padding-top:100px;
    	}
    	.link-wrap {
		    width: 50%;
		    display: flex;
		    align-items: center;
		    height: 100%;
		    box-sizing: border-box;
		    padding-left: 0px;
		    z-index:10;
		    @media(max-width:$mobile){
		    	padding-left:0px;
		    	width:100%;
		    	margin-bottom:25px;
		    }
		    a {
					z-index: 10;
		    	background:$ok_black;
		    	color:$ok_white;
		    	padding: 20px 35px 15px 35px;
			    display: inline-block;
			    font-weight: bold;
					line-height: 100%;
			    letter-spacing: 1px;
			    font-weight: bold;
			    transition: .6s;
			    margin-right: 15px;
			    width: calc(100% - 250px);
			    font-family:$heading_font;
			    @include font-size(1.5);
			    text-transform:capitalize;
			    width:50%;
					max-width: 300px;
					&:hover{
						background: #E62E2E;
						transition: .5s;
					}
			    @media(max-width:$mobile){
			    	width:100%;
						max-width: 100%;
						margin-right: 0;
			    }
		    }
		}
    }
    .sub-two {
    	z-index:4;
    	min-height:150px;
    	width:100px;
    	@media(max-width:$mobile){
    		width:28px;
    	}
    	h6 {
    	margin:0;
    	color:#fff;
    	}
    }
    .tech-slide-wrap {
			position:absolute;
			width:80%;
			height:100%;
			left:0;
			top:0;
			display:flex;
			align-items:center;
			margin-left:24.5%;
			// margin-left:39%;
		
			@media(max-width:$mobile){
				margin-left:25px;
				width:calc(100% - 40px);
				height:100px;
			}
			.tech-slideshow {
				display: flex;
			    position: absolute;
			    right: 0;
			    bottom: 44px;
			    overflow: hidden;
			    width: 80%;
			    height: 68px;
			    border-left: none;
			    margin-bottom: 50px;
			    display: flex;
			   	@include font-size(1.5);
			    margin-top: 0;
			    color: #2C2B2D;
			    margin-bottom: 0;
			    margin-top: 0;
			    margin: auto;
			    top: 0;
				@media(max-width:$mobile){
					 bottom: -31px;
				}
				span {
					font-weight:bold;
					font-family:$heading-font;
					margin:0 !important;
				}
			}
		}
}

/*//////////
RIGHT BUTTON CONTENT
/////////*/
.right-button-link-wrapper {
    width: 100%;
    min-height: 150px;
    display:flex;
    position:relative;
    @media(max-width:$mobile){
    	min-height:200px;
    }
    .sub-one {
    	width: calc(100% - 50px);
	    margin-left: 2px;
	    background: red;
	    margin-right: 100px;
	    @media(max-width:$mobile){
    		margin-left:0;
    		width:100%;
    		padding-top:50px;
    		margin-right:25px;
    	}
    	.link-wrap {
		    width: 50%;
		    display: flex;
		    align-items: center;
		    height: 100%;
		    box-sizing: border-box;
		    padding-left: 0px;
		    z-index: 10;
		    margin-left: 50%;
		    flex-direction: row-reverse;
		    text-align: revert;
		    padding-right: 0px;
		    @media(max-width:$mobile){
		    	padding-right: 0;
			    width: 100%;
			    margin-left:0;
			    margin-bottom: 25px;
		    }
		    a {
					z-index: 10;
		    	background:$ok_black;
		    	color:$ok_white;
		    	padding: 20px 35px 15px 35px;
			    display: inline-block;
			    font-weight: bold;
					line-height: 100%;
			    letter-spacing: 1px;
			    font-weight: bold;
			    transition: .6s;
			    margin-right: 15px;
			    width: calc(100% - 250px);
					max-width: 300px;
			    font-family:$heading_font;
					z-index: 10;
			    @include font-size(1.5);
			    width:50%;
			    text-transform:capitalize;
			    @media(max-width:$mobile){
			    	width:100%;
						max-width:100%;
						margin-right: 0;
						padding-left:0;
						padding-right: 0;
			    }
					&:hover{
						background-color:$ok_red;
						transition:.3s ease-in-out;
					}
		    }
		}
    }
    .sub-two {
    	z-index:4;
    	min-height:150px;
    	width:100px;
    	@media(max-width:$mobile){
    		width:28px;
    	}
    	h6 {
    	margin:0;
    	color:#fff;

    	}
    }
    .tech-slide-wrap {
			position:absolute;
			width:80%;
			height:100%;
			left:0;
			top:0;
			display:flex;
			align-items:center;
			margin-left:0;
			@media(max-width:$mobile){
				margin-right:40px;
				width:calc(100% - 40px);
				height:auto;
				margin-top:25px;
			}
			.tech-slideshow {
				display: flex;
			    position: absolute;
			    right: 0;
			    bottom: 44px;
			    overflow: hidden;
			    width: 80%;
			    height: 68px;
			    margin-bottom: 50px;
			    display: flex;
			   	@include font-size(1.5);
			    margin-top: 0;
			    color: #2C2B2D;
			    margin-bottom: 0;
			    margin-top: 0;
			    margin: auto;
			    top: 0;
				@media(max-width:$mobile){
					bottom:0;
				}
				span {
					font-weight:bold;
					font-family:$heading-font;
					margin:0 !important;
				}

			}
		}


}
