@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';

.component-internal-big-link {
	width:100%;
	animation:linkIn 1.2s 1;
	animation-fill-mode:forwards;
	animation-delay:.3s;
	position:relative;
	top:45px;
	opacity:0;
	width:100%;
	margin:auto;
	@media(max-width:$large_tablet){
		width:100%;
	}
	.award-wrapper {
	    min-width: 200px;
	    display: flex;
	    position: absolute;
	    bottom: 0;
	    background: $ok_black;
	    z-index: 2;
	    top: auto;
	    bottom:75px;
	   	@media(max-width:$mobile){
	   		bottom:50px;
	   	}
	    .column {
	    	width:auto;
	    	margin-bottom:0;
	    	color:$white;
	    	&.col-1 {
	    		max-width:150px;
	    		display:flex;
	    		align-items:center;
	    		justify-content: center;
	    		padding:15px;
	    		padding-left:30px;
	    		@media(max-width:$mobile){
	    			padding-left:15px;
	    		}

	    		img {
	    			max-width:150px;
	    			box-sizing:border-box;
	    			@media(max-width:$mobile){
	    				max-width:75px;
	    			}
	    		}
	    	}
	    	&.col-2 {
	    		padding:15px 25px 7px 25px;
	    		@media(max-width:$mobile){
	    			padding-left:0;
	    		}
	    		h5 {
	    			margin-bottom:15px;
	    			margin-top:15px;
					@include font-size(2.3);
					@media(max-width:$mobile){
						@include font-size(1.5);
						margin-bottom:5px;
						font-family: $heading-font;
					}
	    		}
	    		text-align:left;
	    		section {
	    			margin-bottom:10px;
	    		}
	    		p {
	    			margin-bottom:0;
	    			margin-top:0;
	    			@include font-size(.8);
	    			color:$white;
	    			letter-spacing:1px;
						font-family: $heading-font;
						strong {
							font-family: $sub-heading-font;
							font-weight: 400;
						}
	    		}

	    	}
	    }

		@media (max-width:$mobile){
			bottom: 36px;
			width: 100%;
			.column.col-2{
				h5{
					font-size: 1rem;
				}
				p{
					font-size: .7rem;
				}
			}
		}
	}

	a {
		color:$ok_black;
	}
	img.heading {
	    position: absolute;
	    left:21px;
	    top: 0;
	    z-index: 1;
	    top:-82px;
	    animation: rotateAnimOppOne 15s linear infinite;
	    max-width:150px;
	    @media(max-width:$mobile){
		   	max-width: 150px;
		    top: -75px;
		    left: -46px;
	    }
	}
	@keyframes rotateAnimOppOne {
				100%{
					transform:rotate(+360deg);
				}
			}

	@keyframes linkIn {
		0%{
			top:45px;
			opacity:0;
		}
		100%{
			top:0;
			opacity:1;
		}
	}
	.graphic {
		position: absolute;
	    left: 0;
	    width: 100%;
	    bottom: 200px;
	    width: 100%;
	    max-width: 70px;
	    @media(max-width:$mobile){
	    	max-width:30px;
	    }	
	}
	h3.recent-work-heading {
		text-align:left;
		@include font-size(3);
		font-family:$heading_font;
		color:$ok_black;
		margin-bottom:15px;
		margin-left:93px;
	}
	h3 {
		@include font-size(.9);
	}
	div.sub-one {
		width:100%;
		display:flex;
		> div.bg-image {
			height:100%;
			width:100%;
			margin:auto;
			height:100vh;

			&:hover{
				transition: .5s ease-in;
				opacity: .8;
			}

			@media(max-width:$mobile){
				height:350px;
			}
			h2 {
				margin-top:0;
				@include font-size(10);

			}
		}
	}
	div {
		background-size:cover;
		position:relative;
		cursor:pointer;
		overflow:hidden;
		margin-bottom:15px;
	

		&:nth-child(1) .filter-init{
			animation-delay:1.2s;
		}
		&:nth-child(2) .filter-init {
			animation-delay:1.5s;
		}
		&:nth-child(3) .filter-init{
			animation-delay:1.8s;
		}


		.filter {
			position:absolute;
			width:100%;
			height:0;
			transition:.7s;
			left:0;
			z-index:1;
		}
		span.filter-black {
		    position: absolute;
		    width: 100%;
		    height: 100%;
		    background: rgba(0,0,0,0.1);
		    left: 0;
		    z-index:2;
		}	
		span.filter-ok {
		    position: absolute;
		    left: 0;
		    top: 0;
		    width: 0%;
		    height: 100%;
		    background: $ok_orange;
		    transition:.5s;
		    transition-delay:.8s;
		}


		.filter-init {
			height:100%;
			width:100%;
			position:absolute;
			left:0;
			animation:filterInit .6s 1;
			animation-fill-mode:forwards;



		}

		@keyframes filterInit {
			0%{
				height:100%;
			}
			100%{
				height:0;
			}
		}

		h4 {
			color: #fff;
		    box-sizing: border-box;
		    text-align: left;
		    box-sizing: border-box;
		    padding: 0 50px;
		    text-transform:uppercase;
		    position:relative;
		    transition:.5s;
		    line-height:115%;
		    @include font-size(.8);
		    z-index:2;
		    @media(max-width:$mobile){
		    	padding-left:20px;
		    }
		}
		span.client-info {
		    display: block;
		    position: absolute;
		    bottom: 0;
		    width: 100%;
		    background: #fff;
		    text-align: left;
		    padding-top: 0px;
		}
		h3 {
		    span {
					font-family: $sub-heading-font;
		    	font-weight:400;
		    }
		}

	}
	.spacer-one {
		width:100px;
		z-index: 1;
    	mix-blend-mode: difference;
    	
    	@media(max-width:$mobile){
    		width:15px;
    	}
	}
	.spacer-two {
		width:100px;
		z-index: 1;
    	mix-blend-mode: difference;
    	/*background:#fff;*/
    	@media(max-width:$mobile){
    		width:15px;
    	}
	}
	.tech-slide-wrap {
		position:absolute;
		width:100%;
		height:100%;
		left:0;
		top:0;
		display:flex;
		align-items:center;
		@include font-size(8);
		margin-top:0;
		color:#fff;
		font-family:$heading_font;
		fontw-weight:bold;
		transition:.2s;
			&:hover {
				opacity:.8;
				transition:.2s;
			}
		span {
			font-family:$heading_font;
			font-weight:bold;
		}
		.tech-slideshow {
			display:flex;
			position:absolute;

			.mover-1 {
		
				h2 {
					display:flex;
					@include font-size(15);
					margin-top:0;
					color:#fff;
					@media(max-width:$large_tablet){
						margin-top:72px;
					}
					span {
						font-weight:bold;
						font-family:$heading-font;
					}
				}
			}
		}
	}
	
	@keyframes marquee {
	  0% {
	    transform: translate(0, 0);
	  }
	  100% {
	    transform: translate(-100%, 0);
	  }
	}

}