@import '../../../../Scss/variables.scss';
@import '../../../../Scss/mixins.scss';

.component-read-more-wrapper {
    flex-wrap: wrap;
    width:100%;
    margin: auto;
    text-align: left;
    h2,h3,h4 {
            @include font-size(4);
            text-transform:capitalize;
            margin-bottom:50px;
            color:$ok_black;
        }

    .read-more-wrapper {
    	width-align:center;
    	display:flex;
    	align-items:center;
    	justify-content: center;
    	margin-bottom:50px;
    	margin-top:50px;

    	button {
    		border:none;
    		background:none;
    		@include font-size(1.2);
    		font-family:$heading_font;
    		cursor:pointer;
    		position:relative;
    		z-index:3;
    		img {
    			position:absolute;
    			bottom:-35px;
    			left:0;
    			right:0;
    			margin:auto;
    			animation:arrowDown .9s infinite;
    			transition:.6s;

    		}
    		@keyframes arrowDown {
    			0%{
    				bottom:-32px;
    			}
    			50%{
    				bottom:-35px;
    			}
    			100%{
    				bottom:-32px;
    			}
    		}
    	}
    }
    .sub-wrapper {
	    display: flex;
	    width:100%;
	    display:none;
	    .sub:nth-child(1){
	    	box-sizing:border-box;
	    	padding-right:25px;
	    }
	}

}
.component-read-more-wrapper.active {
	button {
		img {
			transform:rotate(180deg);
			transition:.6s;
		}
		
	}
	.sub-wrapper {
		display:flex;
		@media(max-width:$mobile){
			display:block;
			
		}
		.sub {
			opacity:0;
			animation:contentIn .7s 1;
			animation-fill-mode:forwards;
			position:relative;
			top:15px;
		}
		@keyframes contentIn {
			0%{
				opacity:0;
				top:15px;
			}
			100%{
				opacity:1;
				top:0;
			}
		}
	}
}