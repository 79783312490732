@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';


.uwa-sport-wrapper {
	@media(max-width:$mobile){
		overflow-x:hidden;
	}
	.component-full-bleed-image img {
		@media(max-width:$mobile){
			left:0 !important;
		}
	}
}