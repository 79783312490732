@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';

.component-cta-footer {
    width: calc(100% - 100px);
    height: auto;
    display: flex;
    padding: 0 50px;
    box-sizing: border-box;
    flex-wrap: wrap;
    position: relative;
    margin: auto;
    margin-top: 0px;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 50px;
    margin-top:150px;
    border-top:1px solid rgba(0,0,0,0.3);
    padding-top:50px;
    padding-bottom:50px;
    overflow:hidden;
    margin-bottom:0;
  @media(max-width:$mobile){
    width:calc(100% - 40px);

  }
  .reveal-link {
    height: 130px;
    overflow: hidden;
    padding-top: 50px;
    padding-bottom: 50px;
    box-sizing: border-box;
    @media(max-width:$mobile){
      overflow:visible;
    }
    span {
      position:relative;
      transition:.6s;
       display: block;
       margin-top:0;

    }
    &:hover span {
      position: relative;
      margin-top: -110px;
      transition: .6s;
      
      @media(max-width:$mobile){
        margin-top:0;
      }
     
    }
  }
  .footer-wrapper {
    margin:auto;
    margin-bottom:0px;
    padding-top:50px;
    margin-left:50px;
    @media(max-width:$mobile){
      flex-wrap:wrap;
      margin-bottom:20px;
       width:calc(100% - 40px);
       margin-left:0;
    }
    .bottom-content{
      ul {
         padding-left: 0;
        list-style: none;
        display: flex;
        width: calc(100% - 100px);
        margin-right: 50px;
        @media(max-width:$mobile){
          margin-left:20px;
          display: block;
          text-align: left;
          border-top: 1px solid $ok_black;
          padding-top: 25px;
        }
        li {
          @include font-size(.7);
          color:$ok_black;
          margin-right:15px;
          margin-bottom:5px;
          font-weight:bold;
          a {
            @include font-size(.7);
            color:$ok_black;
            font-weight:bold;
            padding:5px;
            letter-spacing:0.3px;
            @media(max-width:$mobile){
              display:block;
              padding-bottom:0;
              padding-left:0;
            }
          }
        }
      }
    }
    .row h5 {
      @include font-size(2.5);
      margin-bottom: 0;
      margin-top: 0;
      color:$ok_black;
    }

    .left-content {
      display: flex;
      text-align: left;
      flex-wrap: wrap;
      width: 100%;
      margin-right: 50px;
      @media(max-width:$mobile){
        width:100%;
      }
      .row {
        width:33.33%;
        @media(max-width:$mobile){
          width:100%;
        }
      }
      .social-links {
        display:flex;
        @media(max-width:$mobile){
          display:block;
        }
        a {
          margin-right:15px;
          text-decoration:underline;
          @include font-size(1.05);
        }
      }

      .row.row-one {
        text-align: left;
      }
      .row.row-four {
         ul {
         
          img {
            width:15px;
            margin-right:15px;
          }
         }
      }

      ul {
        padding-left:0;
        list-style:none;
        padding-right:20px;
        box-sizing:border-box;
        @media(max-width:$mobile){
          margin-left:0;
        }
        li {
          @include font-size(.7);
          color:$ok_black;
         font-weight:400;
         letter-spacing:0.3px;

          img {
            width:25px;
            margin-right:15px;
          }
          a {
            @include font-size(.7);
            color:$ok_black;
           font-weight:400;
           letter-spacing:0.3px;
          }
        }
      }
    }
    .right-content {
      @media(max-width:$mobile){
        width:100%;
        text-align:left;
        border-top:1px solid #eee;
        padding-top:25px;
      }
      ul {
        list-style:none;
        padding-left:0;
        text-align:left;
        li {
         @include font-size(.8);
         color:$ok_black;
         font-weight:bold;
          a {
            @include font-size(.8);
            color:$ok_black;
            font-weight:bold;
          }
        }
      }
    }

  }
  .contact-links {
    @media(max-width:$mobile){
      text-align:left;
    }
    & span{
      font-family:$sub-heading-font;
      @include font-size(1.5);
      vertical-align: sub;
    }

      .button {
        display: inline-block;
        color: $ok_white;
        font-weight: bold;
        background:$ok_black;
        text-transform: capitalize;
        line-height: 100%;
        letter-spacing: 1px;
        font-weight: bold;
        transition: .6s;
        padding: 0;
        padding-bottom: 5px;
        margin-right: 15px;
        margin-left:50px;
        font-family:$heading_font;
        @include font-size(1.5);
        padding:20px 35px 15px 35px;

        @media(max-width:$mobile){
          width: calc(100% - 65px);
          margin-right: 0;
          text-align: center;
          margin-bottom: 25px;
          margin-left: 0px;
          text-align: left;
          margin-top: 21px;
          display: block;
          text-align:center;
        }
        &:hover {
          background:$ok_green;
          transition:.5s;
        }
    
    }
    a.tel-link {
        font-size: 40px;
        font-family: $heading-font;
        color: $ok_black;
        position: relative;
        top: 8px;
        transition:.6s;
        margin-left:15px;
        @media(max-width:$mobile){
          width:100%;
          text-align:left;
          margin-left:0;
          display:block;
          margin-top:10px;
          @include font-size(2.3);
        }
         &:hover {
          color:$ok_green;
          transition:.5s;
        }
    }

  }

  div {
    width:100%;
    text-align:left;
    z-index:2;
    font-family:$sub-heading-font;

    @media(max-width:$mobile){
      width:100%;
    }

    h3 {
        @include font-size(4);
         margin-bottom:25px;
         color:$font-color;
         line-height:90%;
         transition:.6s;
         margin-top:0;
         margin-bottom:50px;
         font-family:$heading_font;
         font-weight:bold;
         margin-left:50px;
         letter-spacing:-2px;
        
         @media(max-width:$mobile){
          width:100%;
          margin-left:0;

          @include font-size(3);
          &:nth-child(2){
            display:none;
          }
         }
         span {
          font-family:'Raleway',arial,sans-serif;
          @include font-size(1);
          letter-spacing:0;
         }

    }
    p {
      @include font-size(1.3)
      margin-bottom:15px;
      margin-left:0;
      position:relative;
      width:auto;
      line-height:120%;
      color:$font-color;
      width:60%;
      margin-left:50px;
      @media(max-width:$mobile){
         margin-left: 0;
         width: calc(100% - 50px);
         margin-bottom:50px;
      }
    }

    &:nth-child(1){
      position:relative;
    }
  }
}