@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.cicerellos-sea-critters-wrapper {
	.square-block-images.component-case-images-wrapper {
	    max-width: 900px;
	    margin-top:50px;
	    .stat-wrap {
	    	min-height:250px;
	    	padding:25px;
	    	box-sizing:border-box;
	    }
	}
	.animated-animals {
	    width: 100%;
	    display: flex;
	    align-items: center;
	    max-width: 950px;
	    margin: auto;
	    @media(max-width:$tablet){
	    	display:block;
	    	margin-top:50px;
	    	svg {
	    		max-width:250px;
	    	
	    	}

	    }
	}

	.stat-wrapper.component-case-images-gifs-wrapper {
		margin-top: 100px;
		width: 100%;
		img {
			&.side-gif {
				width:25%;
				max-width: 330px;
				border-radius: 32px;
			}
    }
		.insta-wrap {
			position: relative;
			z-index: 88;
			transform: scale(1.2) translateY(30px);
			margin: 0 20px;
			.insta-svg {
				width:400px;
			}
			.insta-vid {
				position: absolute;
				left: 44px;
				top: 80px;
				width: 311px;
			}
			
		}
		
		@media(max-width:1200px){
			.insta-wrap{
				transform: scale(1.1) translateY(30px);
			}
			img.side-gif {
				width: 30%;
			}
		}
		@media(max-width:$large_tablet){
			img.side-gif {
				width: 25%;
			}
			.insta-wrap{
				margin: 0;
				transform: scale(1) translateY(30px);
			}
		}
		@media(max-width:$tablet){
			flex-direction: column;
			img.side-gif {
				width: 100%;
				max-width: 300px;
			}
			.insta-wrap{
				margin: 0;
				transform: scale(1);
			}
		}
		@media(max-width:$mobile){
			img.side-gif{
				max-width:250px;
			}
			.insta-wrap{
				.insta-svg{
					min-width: 325px;
				}
				.insta-vid {
					left: 35px;
					top: 66px;
					width: 255px;
				}
			}	
		}

	}

}