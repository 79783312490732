@import '../../../../Scss/variables.scss';
@import '../../../../Scss/mixins.scss';

.component-case-copy-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: calc(100% - 100px);
    margin: auto;
    text-align: left;
    margin-top:100px;
    position:relative;
    @media(max-width:$mobile){
        max-width:calc(100% - 50px);
        margin-top:50px !important;
    }
    .crumb {
        position: absolute;
        max-width: 150px;
        right: 50px;
        top: -50px;
        width: 100%;
        @media(max-width:$mobile){
            max-width:100px;
            right:0;
        }
    }
    h2 {
        @include font-size(4rem);
        text-transform:capitalize;
        color:$ok_black;
    }
    .sub.sub-one {
    	width:25%;
        box-sizing:border-box;
        padding-right:50px;
        @media(max-width:$mobile){
            width:100%;
        }
    	h4 {
    		@include font-size(2);
    		margin-top:5px;
            line-height:100%;
            color:$ok_black;
            
    	}
    }
    .sub.sub-two {
	    margin-right: 25%;
        margin-left: 25%;
        width: calc(75% - 25%);
        box-sizing: border-box;
        padding-right: 0;
        @media(max-width:$large_tablet){
            width:100%;
            margin-left:0;
            margin-right:0;
            padding-right:0;
        }
        ul {
            padding-left:0;
        }
    }
    img {
    	max-width:100%;
    }

}