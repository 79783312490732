@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';


.hunter-capital-wrapper {

	.square-images.component-case-images-wrapper {
		max-width:1000px;
		margin:auto;	
		@media(max-width:$large_tablet){
			min-height:350px;
			.sp-image-one.stat-wrap,
			.sp-image-two.stat-wrap {
				background-size:250px 250px;
				min-height:300px;
			}
		}
	}
	.square-images-two.component-case-images-wrapper {
		max-width:1500px;
		margin:auto;
		min-height:600px;
		padding-left:50px;
		padding-right:50px;
		box-sizing:border-box;
		@media(max-width:1300px){
			padding-left:25px;
			padding-right:25px;
			@media(max-width:$tablet){
				min-height:600px;
				margin-top:50px;
			}
			.sp-image-one.stat-wrap,
			.sp-image-two.stat-wrap {
				background-size:600px !important;
				@media(max-width:$large_tablet){
					background-size:350px!important;
					min-height:320px;
				}

			}
		}
	}
	
}
