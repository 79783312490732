@font-face{
    font-family:gothicBook;
    src:url('./Media/Fonts/avanteBooker.ttf');
}

@font-face{
    font-family:helveticaNeueLTPro73;
    src:url('./Media/Fonts/HelveticaNeueLTPro-BdEx.otf');
}

@font-face{
  font-family:helveticaNeueLTPro53;
  src:url('./Media/Fonts/HelveticaNeueLTPro-Ex.otf');
}

@font-face {
  font-family: "avantegardepro";
  src: url(./Media/Fonts/ITC-ITCAvantGardePro-Bk.otf) format('truetype') font-weight-normal,
       url(./Media/Fonts/ITC-ITCAvantGardePro-Bold.otf) format('truetype') font-weight-bold,
       url(./Media/Fonts/ITC-ITCAvantGardePro-BoldObl.otf) format('truetype') font-italic font-weight-bold;

}


@keyframes bodyIn {
  0%{
    opacity:0;
  }
  100%{
    opacity:1;
  }
}

:focus { outline: none; }

*{
  -webkit-font-smoothing: antialiased;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    width: 100%;
    min-height: 100vh;
    background-size:cover;
    background-position:center;
    max-width:  1920px;
    margin:auto;
    overflow-x:hidden;

}
.react-reveal {
  opacity:0;
  position:relative;
  top:35px;
  transition:.6s;
}
.fadeInUp {
  top:0;
  opacity:1;
  transition:.95s;
  transition-delay:.6s;
}
.fadeInUpCase {
  top:0;
  opacity:1;
  transition:.95s;
}



html {
    opacity:0;
    animation:bodyIn .6s 1;
    animation-fill-mode:forwards;
    overflow-x: hidden;
}


h1,h2,h3,h4,h5,h6 {
  font-family:"helveticaNeueLTPro73", sans-serif;
  font-weight:bold;
  letter-spacing: 0.4px;
}

h2 span,
h3 span,
h4 span,
h5 span,
h6 span
 {
  font-family:"helveticaNeueLTPro73", sans-serif;
  font-weight:bold;
}

input[type="submit"],button {
  -webkit-appearance: none;
-moz-appearance: none;
appearance: none;
}
a {
  text-decoration:none;
}
h2 {
    font-size: 5.5rem;
    text-transform: uppercase;
    margin-top: 50px;
    margin-bottom: 0;
}
.weight-bold {
  font-family:"Helvetica Neue","Helvetica","Helvetica", arial, sans-serif;
}
p,li,span,a{
  font-family:"Helvetica Neue","Helvetica","gothicBook", "Open Sans", sans-serif;
  font-weight:100;
  line-height:140%;
  font-weight: 400;
  letter-spacing: 1px;
  color: #2B2C2D;
}
p span,p {
  letter-spacing: 1px;
  letter-spacing: 1px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

