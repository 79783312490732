@import '../../../../Scss/variables.scss';
@import '../../../../Scss/mixins.scss';

 .footer-images.component-case-images-wrapper .stat-wrap {
        @media(max-width:$mobile){
            min-height:250px;
        }
    }
.component-case-images-wrapper {
    display: flex;
    width: 100%;
    margin-top: 60px;
    max-width: calc(100% - 200px);
    margin: auto;
    margin-top: 0;
    @media(max-width:$large_tablet){
        max-width:calc(100% - 50px);
        flex-wrap:wrap;
    }

   
    .stat-wrap {
    	width:100%;
    	box-sizing:border-box;
    	padding:25px;
    	color:$ok_white;
    	min-height:400px;
    	display:flex;
    	align-items:center;
    	justify-content:center;
        background-size:cover;
        background-position:center;
   
        @media(max-width:$large_tablet){
            width:100% !important;
            background-position:center !important;
        }
    	h4 {
    		@include font-size(6);
    		margin-bottom:25px;
    		margin-top:20px;
		}
		p {
			font-family:$heading_font;
			@include font-size(3);
			margin-top:0;
			line-height:110%;
			margin-bottom:0;
		}

    }
}