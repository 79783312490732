@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.murdoch-uni-wrapper {
	.square-block-images.component-case-images-wrapper {
		max-width:1002px;
		padding-top:50px;
		.sp-image-two.stat-wrap {
		    position: relative;
		    top: 25px;
		    @media(max-width:$large_tablet){
		    	top:0;
		    }
		}
	}

	.component-case-images-gifs-wrapper {
		flex-wrap:wrap;
		width:70%;
		margin:auto;
		img {
			margin:15px;
		}
		img:nth-child(1){
			box-sizing:border-box;
			padding:120px;
			@media(max-width:$large_tablet){
				padding:50px;;
			}
			@media(max-width:$mobile){
				padding:0;
				margin-bottom:50px;
			}
		}
		img:nth-child(4){
			box-sizing:border-box;
			padding:170px;
			@media(max-width:$large_tablet){
				padding:100px;
			}
			@media(max-width:$mobile){
				padding:50px;
			}
		}
	}

}