@import '../../../Scss/variables.scss';
@import '../../../Scss/mixins.scss';

.heart-foundation-wrapper {
	video {
		border-radius:10px;
	}
	.heart-multi-image-wrap {
	    padding: 0 100px;
	    margin-top: 100px;
	    @media(max-width:$mobile){
	    	padding:0 25px;
	    }
	    .row.row-1 {
		    display: flex;
		    flex-wrap:wrap;
		    img {
			    max-width: 100%;
			    width: 50%;
			    @media(max-width:$mobile){
			    	width:100%;
			    }
			}
		}
		.row.row-2 {
		    position: relative;
		    margin-top: -150px;
		    @media(max-width:$mobile){
		    	margin-top:0;
		    }
		    img {
		    	max-width:55%;
		    	@media(max-width:$mobile){
		    		max-width:100%;
		    		width:100%;
		    	}
		    }
		}
	}
}