@import '../../Scss/variables.scss';
@import '../../Scss/mixins.scss';
@import '../../Scss/hamburgers.scss';

.component-header.blob-active { 
  .cursor-custom {
   .blob-one {
    g {
      fill:$ok_green;
      transition:.2s;
    }
   }
  }
}
.component-header {

  .cursor-custom {
    width:3rem;
    height:3rem;
    position:absolute;
    border-radius:100%;
    z-index:9999999999;
    display:none;
    transform:translate(-50%, -50%);
    pointer-events:none;

    .blob-one {
          right: auto;
          top: auto;
          g {
            fill:$ok_red;
            transition:.2s;
          }
    }

  }

  .cursor-custom.active {
    display:block;
    animation:cursorIn 1.8s 1;
    animation-fill-mode:forwards;
    animation-delay:.5s;
    opacity:0;
    transform:scale(1.2);
     @media(max-width:$tablet){
        display:none ;
    }
  }
  @keyframes cursorIn {
    0%{
      opacity:0;
      transform:scale(1.2);
    }
    100%{
      opacity:1;
      transform:scale(1);
    }
  }
  .nav-wrap-desktop.hamburger {
      margin-left: 25px;
      position:relative;
      top:-10px;
      @media(max-width:$large_tablet){
        display:none;
      }
      .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
          width: 20px;
          height: 2.5px;
      }
      .hamburger-inner::before {
        top:-5px;
      }
      .hamburger-inner::after {
        bottom: 10px;
        top:auto;
      }
      button.hamburger.hamburger--collapse.is-active {
        position: relative;
        top: 5px;
        .hamburger-inner::before {
            top: 0 !important;
        }
      }
  

  }
  .desktop-drop-menu {
    position: fixed;
    width: 100%;
    height: 0%;
    top:0;
    left:0;
    display:flex;
    margin-top:-100%;
    transition:.8s;
    transition-delay:1s;
    @media(max-width:$large_tablet){
      display:none;
    }

    a {
      
    }

    .desktop-drop-col-1 {
      width:40%;
      display:flex;
      align-items:center;
      justify-content: center;
      background:$ok_black;
      position:relative;
      transition:.9s;
      height:0;
      border-right:1px solid rgba(255,255,255,0.2);
      

      ul {
        .team-work {
          font-size: 30px;
          width: 255px;
          line-height: 100%;
          margin-bottom: 25px;
        }
        li {
         a {
             opacity:0;
            font-family:$heading-font;
            color:$ok_white;
            line-height:100%;
            @include  font-size(4);
            font-weight:bold;
            position:relative;
            top:25px;
            transition:.4s;
            transition-delay:.1s;

            @media(max-width:$small_desktop){
              @include font-size(4);
            }

          }

        }
      }
    }
    .desktop-drop-col-2 {
      width:30%;
      background:$ok_black;
      transition:.6s;
      height:0;
      transition-delay:.4s;
      display:flex;
      align-items:center;
      justify-content: center;
      ul {
        li {
          
          a {
            opacity:0;
            color:$ok_white;
            line-height:140%;
            @include  font-size(1);
            position:relative;
            font-family:$sub-font;
            top:25px;
            transition:.4s;
            transition-delay:.1s;
            display:block;
           
          }
          a.special-contact {
            @include font-size(2);
            font-family:$heading-font;
             @media(max-width:$small_desktop){
              @include font-size(1.8);
            }
            &:nth-child(1){
              margin-top:25px;
            }
          }
          a.special-contact-second {
            font-family:$heading-font;
            @include font-size(2.2);
            @media(max-width:$small_desktop){
              @include font-size(1.8);
            }
          }
        }
      }
    }
    .desktop-drop-col-3 {
      width:30%;
      background:rgba(0,0,0,0.5);
      transition:.6s;
      height:0;
      transition-delay:.6s;
      display:flex;
      align-items:center;
      justify-content: center;
      position:relative;
      overflow:hidden;
      
      img.backdrop {
        transform: rotate(-90deg);
        position: absolute;
        z-index: 0;
        left: calc(0% - 670px);
        transition:.6s;
      }
      &:hover {
        transition:.6s;
        width:40%;
        transition-delay:0s !important;
        ul {
          li {
            a {
              color:$ok_black;
            }
          }
        }
      }
      ul {
        position:relative;
        z-index:1;
        margin-left:35%;
        li {
          a.team-work {
            @include font-size(2);
            margin-bottom:25px;
            width:255px;
            font-family:$heading-font;
            line-height:100%;
          }
          a {
            opacity:0;
            color:$ok_white;
            line-height:140%;
            @include  font-size(1);
            font-family:$sub-heading-font;
            position:relative;
            top:25px;
            transition:.4s;
            transition-delay:.1s;
            display:block;
          }
        }
      }
    }
    ul {
      padding-left:0;
      text-align:left;
      margin:0;
      list-style:none;
      transition:.6s;
    }
    
  }
  // activate desktop modal burger 
  .desktop-drop-menu.is-active {
    margin-top:0;
    transition:.6s;
    .desktop-drop-col-1 {
      height:100vh;
      transition:.6s;
      ul {
        li {

          a {
            opacity:1;
            top:0;
            transition:.5s;
            @include font-size(4);
            transition-delay:1s;
            &:hover {
                transition-delay:.0s !important;
                color:$ok_green;
                transition:.5s;
               }
           }

          @for $i from 1 through 10 {
             &:nth-child(6n + #{$i}) a {
                 transition-delay: $i * 0.20s;
                 
              }
            }
         }
      }
      
    }
    .desktop-drop-col-2 { 
      height:100vh;
      transition:.6s;
      transition-delay:.4s;
      ul {
        li {

           a {
            opacity:1;
            top:0;
            transition:.5s;
                &:hover {
                  transition-delay:.0s !important;
                  color:$ok_green;
                  transition:.5s;
                 }
           }

          @for $i from 1 through 10 {
             &:nth-child(6n + #{$i}) a {
                 transition-delay: $i * 0.20s;
                
              }
            }
          

          
           
        }
      }
    }
    .desktop-drop-col-3 {
      height:100vh;
      transition:.6s;
      &:hover {
        transition-delay:0s !important;
      }
       img.backdrop {
        transform: rotate(-90deg);
        position: absolute;
        z-index: 0;
        left: calc(0% - 510px);
        transition:.9s;
        transition-delay:1s;
      }
      ul {
        li {
          @for $i from 1 through 10 {
             &:nth-child(6n + #{$i}) a {
                 transition-delay: $i * 0.20s;
                
              }
            }
          

           a {
            opacity:1;
            top:0;
            transition:.5s;
              &:hover {
                  transition-delay:.0s !important;
                  color:$ok_red;
                 }
           }
        }
      }
    }

  }
  div#mobile-active.desktop {
    background:none;
  }
  .desktop.scroll-active {
        background:$ok_white;

  }
  .desktop {
    max-width:1920px;
    box-sizing:border-box;
    padding:25px 100px;
    display: flex;
    z-index: 999999;
    position:fixed;
    width: 100%;
    padding-bottom:15px;
    @media(max-width:$mobile){
      padding:25px 20px;
    }

    .logo-wrap {
      width:50%;
      display:flex;
      align-items:center;

    }
    .nav-wrap.hamburger {
      display:none;
       @media(max-width:$mobile){
          display:block;
          text-align:right;
        }
    }
    .nav-wrap.is-active {
      a {
        color:$ok_white;
        opacity:0;
        z-index:-10;
      }
      .hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
        transition-delay:.1s;
        transition:.5s;
        background-color:$ok_white;
      }
    }
    .nav-wrap {
      width:50%;
      display:flex;
      flex-flow:row-reverse;
      @media(max-width:$mobile){
        display:none;
      }

      a.active {
        color:red;
      }
      a {
        @include font-size(.85);
        padding: 0px 0px 0px 27px;
        display: block;
        color:$font-color;
        letter-spacing: .5px;
        flex-direction: row;
        align-items: center;
        font-family: DM Sans,sans-serif;
        font-size: .8rem;
        font-weight: 500;
        line-height: 19px;
        text-decoration: none;
        transition: all .4s;
        &:hover {
          color:$ok_orange;
          transition:.6s;
        }
      }
    }
  }
  .mobile-pop {
      position: fixed;
      width: 100% inherit;
      width: 100%;
      height: 100%;
      background: $ok_green;
      display: flex;
      z-index: 9999;
      top:-100%;
      transition:.6s;
      padding-top:90px;
      box-sizing:border-box;
      display:none;
      @media(max-width:$mobile){
        display:flex;
      }
      .blob-one {
        opacity:0;
        transition:.5s;
      }
      .socials-wrapper-mob {
        padding-left:55px;
        position:relative;
        ul {
          display: flex;
          transform: rotate(-90deg);
          bottom: 0;
          opacity:0;
          transition: .6s;
          transition-delay: .7s;
          right: 0;
          left:0;
          li {
            a {
              color:$ok_black;
              text-decoration: underline;
              padding:0px 5px;
              display:block;
              @include font-size(.85);
              font-family:$sub-heading-font;
            }
          }
        }
      }
      .page-links-wrapper {
        width:calc(100% - 60px);
        border-right:1px solid rgba(255,255,255,0.3);
          a {
            display: block;
            text-align: left;
            font-size: 10vw;
            margin-left: 20px;
            font-weight: bold;
            color: #2C2B2D;
            font-family: $heading-font;
            line-height: 100%;
            opacity:0;
            position:relative;
            top:15px;
            letter-spacing:0;
        }
         a.mini-link.one {
          margin-top:15px;
         }
        a.mini-link{
          
          font-size:5vw;
          font-family: $sub-heading-font;
          letter-spacing:0;
          margin-left:29px;
          line-height:140%;
        }
        a.mob-contact.one {
          margin-top:15px;

        }
        a.mob-contact {
          font-size:6vw;
          letter-spacing:-0.5px;
          line-height:140%;
          margin-left:29px;
        }
      }
      .socials-wrapper-mob {
        ul {
          list-style:none;
          padding-left:0;
          position:absolute;
          opacity:0;
          
        }
      }
  }
  .mobile-pop.is-active {
    top:0;
    transition:.6s;
    .blob-one {
      opacity:1;
      transition:.4s;
      transition-delay:.6s;
    }
    .page-links-wrapper a {
      opacity:1;
      transition:.6s;
      top:0;
    }
    @for $i from 1 through 20 {
      .page-links-wrapper a:nth-of-type(6n + #{$i}) {
         transition-delay: $i * 0.125s;
      }
    }
    .socials-wrapper-mob {
        ul {
          opacity:1;
          bottom:50px;
          transition:.6s;
          transition-delay:.7s;
        }
      }
  }
}